import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import HomeNew from "./components/Home/HomeNew";
import About from "./components/About/About";
import ContactPage from "./components/Contact/ContactPage";
import Itinerary from "./components/Itinerary/Itinerary";
import TourTrips from "./components/Tours/TourTrips";
import PlanATrip from "./components/PlanATrip/PlanATRip";
import TourTripPdf from "./components/Tours/TourTripPdf";
import TourDetails from "./components/Tours/TourDetails";
import TourTripEnrollment from "./components/Tours/TourTripEnrollment";
import Vehicles from "./components/VIP/Vehicles/Vehicles";
import Gallery from "./components/Gallery/Gallery";
import ViewGallery from "./components/Gallery/ViewGallery";
import FaqPage from "./components/FAQ/FaqPage";
import ThankYou from "./components/Tours/ThankYou";
import ClaimForm from "./components/Tours/ClaimForm";
import InfoPage from "./components/Tours/InfoPage";
import ClaimStatus from "./components/Tours/ClaimStatus";
import TravelInsurance from "./components/Tours/TravelInsurance";
import Modal from "react-modal";

import BookVehicleForm from "./components/Tours/BookVehicleForm";
import VehicleDetails from "./components/VIP/Vehicles/VehicleDetails";
import BookVehicleForm1 from "./components/Tours/BookingVehicleForm1";
import VehicleBookingStatus from "./components/Tours/VehicleBookingStatus";

Modal.setAppElement("#root");
function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeNew />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/itinerary" element={<Itinerary />} />
      <Route path="/thank-you-page" element={<ThankYou />} />
      <Route path="/vehicle-request" element={<BookVehicleForm />} />
      <Route
        path="/vehicle-book-request/:vehicle_id"
        element={<BookVehicleForm1 />}
      />
      <Route
        path="/view-vehicle-detail/:vehicle_id"
        element={<VehicleDetails />}
      />
      <Route path="/view-tours/:category_id" element={<TourTrips />} />
      <Route path="/view-tour-pdf/:tour_pdf_id" element={<TourTripPdf />} />
      <Route
        path="/view-tour-Enrollment/:tour_Enrollment_id"
        element={<TourTripEnrollment />}
      />
      <Route path="/view-tour-details/:tour_id" element={<TourDetails />} />
      <Route path="/plan-a-trip" element={<PlanATrip />} />
      <Route path="/vehicles" element={<Vehicles />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/view-gallery/:gallery_id" element={<ViewGallery />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/claim" element={<ClaimForm />} />
      <Route
        path="/vehicle-booking-status"
        element={<VehicleBookingStatus />}
      />
      <Route path="/claimstatus" element={<ClaimStatus />} />
      <Route path="/info" element={<InfoPage />} />
      <Route path="/insurance" element={<TravelInsurance />} />
    </Routes>
  );
}

export default App;

import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Box, Typography, Divider, Button } from "@mui/material";
import { pdf } from "@react-pdf/renderer";
// import AgreementPdf from "./AgreementPdf";
import foglar from "../../assets/foglar.webp";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";

const AgreementContent = React.forwardRef((props, ref) => {
  const {
    date,
    clientName,
    clientAddress,
    passportNumber,
    airline,
    flightDetails,
    onSignatureAdded,
    onPdfGenerated,
  } = props;

  const sigCanvasRef = useRef();
  const contentRef = useRef();

  const [signatureCaptured, setSignatureCaptured] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  const handleEnd = () => {
    if (sigCanvasRef.current) {
      const signatureCanvas = sigCanvasRef.current.getTrimmedCanvas();
      if (signatureCanvas) {
        const signatureData = signatureCanvas.toDataURL("image/png");
        setSignatureCaptured(true);
        onSignatureAdded(signatureData);
        generatePDF(); // Generate PDF when signature is captured
      } else {
        console.error("Signature canvas is not available.");
      }
    } else {
      console.error("SignatureCanvas reference is not available.");
    }
  };

  // const generatePDF = async (signatureData) => {
  //   const doc = (
  //     <AgreementPdf
  //       date={date}
  //       clientName={clientName}
  //       clientAddress={clientAddress}
  //       passportNumber={passportNumber}
  //       airline={airline}
  //       flightDetails={flightDetails}
  //       signatureData={signatureData}
  //     />
  //   );

  //   try {
  //     const pdfBlob = await pdf(doc).toBlob();
  //     setPdfBlob(pdfBlob);
  //     onPdfGenerated(pdfBlob); // Update PDF Blob in parent component
  //   } catch (error) {
  //     console.error("Failed to generate PDF:", error);
  //   }
  // };

  const generatePDF = () => {
    const content = contentRef.current;
    domtoimage
      .toPng(content)
      .then((imgData) => {
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
        const pdfBlob = pdf.output("blob");
        setPdfBlob(pdfBlob);
        props.onPdfGenerated(pdfBlob); // Update PDF Blob in parent component
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div ref={ref}>
      <Box
        sx={{
          position: "relative",
          padding: 2,
          backgroundColor: "#fff",
          borderRadius: 2,
          boxShadow: 1,
          breakInside: "avoid",
        }}
        ref={contentRef}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={foglar}
            alt="Logo"
            style={{
              width: "150px",
              height: "auto",
              marginRight: "20px",
            }}
          />
        </div>

        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Assignment Agreement
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Concluded on {date} by and between:
        </Typography>
        <Typography paragraph>
          {clientName}, residing at {clientAddress}, passport number{" "}
          {passportNumber}, hereinafter referred to as the{" "}
          <strong>“Client”</strong> and <strong>Kevin Foglar</strong>,
          conducting business as Foglar Law Firm Kevin Foglar, registered at ul.
          Węgierska 33p, 33-340 Stary Sącz, Poland, NIP: 7343586718, REGON
          387115000, hereinafter referred to as the <strong> “Provider”</strong>
        </Typography>

        <Divider />

        <Box component="section" mb={3}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <strong>§1</strong>
          </Typography>
          <Typography paragraph>
            1. The Client declares that it has a claim against {airline} for
            compensation on the basis of Regulation (EC) No 261/2004 for a
            delayed/canceled flight ({flightDetails}), increased by potential
            claims for damages suffered and interest for late payment (the
            <strong>“Claim”</strong>).
          </Typography>
          <Typography paragraph>
            2. The Client declares that he remains fully entitled to the Claim
            and that it has not been assigned to any third parties nor have any
            third parties been engaged in trying to assert the Claim.
          </Typography>
          <Typography paragraph>
            3. The Client hereby assigns its Claim and all rights arising
            therein to the Provider, allowing the Provider to assert the Claim
            in its own name and to conduct all legal action, negotiations and
            make any settlements with the airline at its discretion. The
            Provider is further entitled to collect any and all compensation
            received from the airline and is later obliged to transfer it to the
            Client after offsetting the agreed fee.
          </Typography>
          <Typography paragraph>
            4. The Provider acts with due diligence and strives to acquire
            compensation on behalf of the Client. The Provider does not,
            however, guarantee that his services will be successful in acquiring
            the compensation and may therefore not be held liable solely based
            on the fact that the services did not result in receipt of
            compensation. The Client is aware that it may take many months (even
            years) before any compensation is potentially acquired.
          </Typography>
          <Typography paragraph>
            5. Additional clauses related to this Assignment Agreement, in
            particular the fee charged by the Provider, obligations of the
            Parties and limitations of liability, are subject to the Provider’s
            Terms and Conditions which constitute an integral part of this
            Assignment Agreement.
          </Typography>
        </Box>

        <Divider />
        <Box component="section" mb={3}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            <strong>§2</strong>
          </Typography>
          <Typography paragraph>
            1. This Assignment Agreement is subject to Polish law and the
            jurisdiction of Polish courts.
          </Typography>
          <Typography paragraph>
            2. The Assignment Agreement was concluded in two copies.
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ textAlign: "center" }}>
              <Typography variant="body2" color="textSecondary">
                <div
                  style={{ width: "300px", height: "70px" }}
                  className="signatureContainer"
                ></div>
                _________________________________________
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Provider
              </Typography>
            </div>
            <div style={{ textAlign: "center" }}>
              <Typography variant="body2" color="textSecondary">
                <SignatureCanvas
                  ref={sigCanvasRef}
                  penColor="black"
                  canvasProps={{
                    width: 300,
                    height: 65,
                    className: "sigCanvas",
                  }}
                  onEnd={handleEnd}
                  className="signatureContainer"
                />
                ___________________________________________
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Client
              </Typography>
            </div>
          </div>
        </Box>

        <Divider />

        {/* <div className="d-flex justify-content-between fw-bold ">
          <div className="d-flex flex-column justify-content-start ">
            <div variant="body2" color="textSecondary">
              Foglar Law Firm
            </div>
            <div variant="body2" color="textSecondary">
              NIP: 7343586718
            </div>
          </div>
          <div className="d-flex flex-column justify-content-start ">
            <div variant="body2" color="textSecondary">
              claims@foglar.pl
            </div>
            <div variant="body2" color="textSecondary">
              www.foglar.pl
            </div>
          </div>
        </div> */}

        <div className="d-flex justify-content-between fw-bold ">
          <div className="d-flex flex-column justify-content-start ">
            <div variant="body2" color="textSecondary">
              Foglar Law Firm(NIP: 7343586718)
            </div>
          </div>
          <div className="d-flex flex-column justify-content-start ">
            <div variant="body2" color="textSecondary">
              www.foglar.pl
            </div>
          </div>
          <div className="d-flex flex-column justify-content-start ">
            <div variant="body2" color="textSecondary">
              claims@foglar.pl
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
});

export default AgreementContent;

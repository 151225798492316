import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import Backdrop from "@material-ui/core/Backdrop";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import moment from "moment";
import { Form, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Nav from "./../NavBar/Nav";
import Footer from "./../NavBar/Footer";
//import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Parser from "html-react-parser";
import FooterNew from "../NavBar/FooterNew";

toast.configure();

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Itinerary() {
  const isoDateString = new Date().toISOString();
  const datenow2 = isoDateString.split(".")[0];
  const [date_of_birth, setBirthDate] = useState();
  const [dateOfBirthStatus, setBirthDateStatus] = useState();

  const [value, setValue] = React.useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const [backdropopen, setbackdropopen] = useState(false);
  const [dateType, setDateType] = useState("text");
  const [userdata, setuserdata] = useState();
  const [newline, setnewline] = useState([]);

  const [bannerTitle, setBannerTittle] = useState("");
  const [body, setBody] = useState("");
  const [bannerCaption, setBannerCaption] = useState("");
  const [bannerImage, setBannerImg] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(
        `${base_url.api1}/cmsapp/cms?id=20` //Live
        //`${base_url.api1}/cmsapp/cms?id=63` //Test
      )
      .then(
        (response) => {
          console.log(response.data.Cms);
          setBannerTittle(response.data.Cms.title);
          setBannerCaption(response.data.Cms.caption);
          setBannerImg(response.data.Cms.image);
          setBody(response.data.Cms.body);
        },
        (error) => {
          console.log(JSON.stringify(error));
        }
      );
  };

  const onSubmit = (data2) => {
    if (date_of_birth == undefined) {
      setBirthDateStatus(true);
      return;
    }
    let first_name = data2.first_name;
    let last_name = data2.last_name;
    let birth_date = moment(date_of_birth).format("YYYY-MM-DD");

    let data = {
      first_name,
      last_name,
      birth_date,
    };
    // console.log(data);
    postUser(data);
  };

  const postUser = (data) => {
    setnewline([]);
    axios.post(`${base_url.api1}/tourapp/itenary`, data).then(
      (response) => {
        setuserdata(response.data.tour[0]);
        for (let i = 0; i < response.data.tour[0].Locations.length; i++) {
          setnewline((prev) => [
            ...prev,
            {
              created_date: response.data.tour[0].Locations[i].created_date,
              hotel_name: response.data.tour[0].Locations[i].hotel_name,
              id: response.data.tour[0].Locations[i].id,
              tour_city: response.data.tour[0].Locations[i].tour_city,
              tour_country: response.data.tour[0].Locations[i].tour_country,
              tour_id: response.data.tour[0].Locations[i].tour_id,
              tour_location: response.data.tour[0].Locations[i].tour_location,
              tour_state: response.data.tour[0].Locations[i].tour_state,
              ticket_details: response.data.tour[0].Locations[i].ticket_details,
              insurance_details:
                response.data.tour[0].Locations[i].insurance_details,
              cell_phone: response.data.tour[0].Locations[i].cell_phone,
              hotel_rating:
                response.data.tour[0].Locations[i].hotel_details.hotel_rating,
              hotel_name:
                response.data.tour[0].Locations[i].hotel_details.hotel_name,
              country: response.data.tour[0].Locations[i].hotel_details.country,
              state: response.data.tour[0].Locations[i].hotel_details.state,
              hotel_web_site:
                response.data.tour[0].Locations[i].hotel_details.hotel_web_site,
              flight_name: response.data.tour[0].Locations[i].flight_name,
              departure_from: response.data.tour[0].Locations[i].departure_from,
              arrival_to: response.data.tour[0].Locations[i].arrival_to,
              departure_time: response.data.tour[0].Locations[i].departure_time,
              arrival_time: response.data.tour[0].Locations[i].arrival_time,
            },
          ]);
        }
      },
      (error) => {
        setuserdata();
        // toast.error("User Not Created!");
        Swal.fire({
          position: "center",
          width: "25rem",
          icon: "error",
          title: "No Data Available!",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    );
  };

  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <Nav />
      <div style={{ position: "relative" }}>
        {/* <div className="hero-main top_space" id="logo">
          <div className="hero-main-img">
            <img src="assets/image/inside-header'.jpg" alt="Header_Image" />
            <h1 className="tour_h1_new">{bannerTitle}</h1>
            <p className="tour_head_text" style={{ padding: "0 15%" }}>
              {Parser(body)}
            </p>
          </div>
        </div> */}

        <div className="contact_detail p-0">
          <div className="contact_detail_3 p-0">
            <div className="about">
              <div className="maincon">
                <div id="booking" className="section">
                  <div className="section-center">
                    <div className="container">
                      <Row>
                        <Col md={4}>
                          <div
                            className="opc"
                            style={{
                              backgroundcolor: "rgb(255, 255, 255)",
                              opacity: "1",
                              color: "rgb(255, 255, 255)",
                            }}
                          >
                            <div className="booking-form">
                              <form
                                id="myForm"
                                style={{ marginTop: "-5px" }}
                                onSubmit={handleSubmit(onSubmit)}
                              >
                                <div className="text-center">
                                  <p
                                    className="trip_booking_2"
                                    style={{ textAlign: "center" }}
                                  >
                                    <b>FIND TRIP DETAILS</b>
                                  </p>
                                </div>
                                <div className="form-group">
                                  {/* <span className="form-label">First Name</span> */}
                                  <input
                                    type="text"
                                    className="form-control underline-input"
                                    placeholder="First Name"
                                    {...register("first_name", {
                                      required: true,
                                    })}
                                  />
                                  {errors.first_name && (
                                    <p className="errormessage">
                                      First Name is Required
                                    </p>
                                  )}
                                </div>
                                <div className="form-group">
                                  {/* <span className="form-label">Last Name</span> */}
                                  <input
                                    type="text"
                                    className="form-control underline-input"
                                    placeholder="Last Name"
                                    {...register("last_name", {
                                      required: true,
                                    })}
                                  />
                                  {errors.last_name && (
                                    <p className="errormessage">
                                      Last Name is Required
                                    </p>
                                  )}
                                </div>
                                <div className="form-group">
                                  {/* <span className="form-label">Date Of Birth<span style={{ color: "red" }}></span></span> */}
                                  {/* <input
                                  placeholder="Date Of Birth"
                                  className="form-control underline-input"
                                  type={dateType}
                                  onBlur={()=>setDateType('text')}
                                  onFocus={()=>setDateType('date')}
                                  id="date"
                                  date
                                  onChange={(e)=>{
                                    setBirthDate(e.target.value);
                                    setBirthDateStatus(false)
                                  }}
                                /> */}
                                  <Controller
                                    control={control}
                                    name="birth_date"
                                    render={({ field }) => (
                                      // <DatePicker
                                      //   placeholderText="Date Of Birth"
                                      //   className="form-control underline-input"
                                      //   onChange={(date) => {
                                      //     console.log(date);
                                      //     field.onChange(date);
                                      //     setBirthDate(date);
                                      //     setBirthDateStatus(false);
                                      //   }}
                                      //   selected={field.value}
                                      // />
                                      <DatePicker
                                        selected={field.value}
                                        placeholderText="Date Of Birth                              mm-dd-yyyy"
                                        className="form-control underline-input"
                                        onChange={(date) => {
                                          console.log(date);
                                          field.onChange(date);
                                          setBirthDate(date);
                                          setBirthDateStatus(false);
                                        }}
                                        dateFormat="MMMM d, yyyy"
                                      />
                                    )}
                                    // {...register("birth_date", { required: true })}
                                  />
                                  {/* <input
                                  type="date"
                                  className="form-control underline-input"
                                  placeholder="Date Of birth"
                                  {...register("birth_date", {
                                    required: true,
                                  })}
                                /> */}
                                  {dateOfBirthStatus ? (
                                    <p className="errormessage">
                                      Date Of Birth is Required
                                    </p>
                                  ) : null}
                                </div>
                                <div className="form-btn text-center">
                                  <button className="submit-btn">Submit</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </Col>
                        <Col md={8}>
                          {userdata != null ? (
                            <div className="opc">
                              <div className="booking-form itenary-form">
                                <Box sx={{ width: "100%", minHeight: "419px" }}>
                                  <Box
                                    sx={{
                                      borderBottom: 1,
                                      borderColor: "divider",
                                    }}
                                  >
                                    <Tabs
                                      value={value}
                                      onChange={handleChange}
                                      // variant="scrollable"
                                      // scrollButtons="auto"
                                      // aria-label="scrollable auto tabs example"
                                      indicatorColor="secondary"
                                      // textColor="inherit"
                                      variant="fullWidth"
                                      aria-label="full width tabs example"
                                    >
                                      <Tab
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "15px",
                                        }}
                                        label="Tour Details"
                                        {...a11yProps(0)}
                                      />
                                      <Tab
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "15px",
                                        }}
                                        label="Organizer Details"
                                        {...a11yProps(1)}
                                      />
                                      <Tab
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "15px",
                                        }}
                                        label="Itinerary Details"
                                        {...a11yProps(2)}
                                      />
                                    </Tabs>
                                  </Box>
                                  <TabPanel value={value} index={0}>
                                    <Row className="user_data">
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Tour Name:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.tour_names}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Tour Type:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.tour_types}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Tour Duration:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.tour_duration}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Number Of People:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.number_of_people}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row className="user_data">
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Mode Of Travel:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.mode_of_tour}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Tour Start Date:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {moment(
                                            userdata.duartion_start_date
                                          ).format("DD-MM-YYYY")}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Tour End Date:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {moment(
                                            userdata.duartion_end_date
                                          ).format("DD-MM-YYYY")}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">Status:</b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.tour_status}
                                        </p>
                                      </Col>
                                    </Row>
                                  </TabPanel>
                                  <TabPanel value={value} index={1}>
                                    <Row className="user_data">
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            First Name:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.first_name}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Last Name:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.last_name}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">Email:</b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.email_address}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">Phone:</b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.phone_number}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row className="user_data">
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">Country:</b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.nationality}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">State:</b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.organizer_state}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">City:</b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.organizer_city}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">Address:</b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.organizer_address}
                                        </p>
                                      </Col>
                                    </Row>
                                    <Row className="user_data">
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">Gender:</b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.gender}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Date Of Birth:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.date_of_birth}
                                        </p>
                                      </Col>
                                      <Col md={3}>
                                        <label>
                                          <b className="fontChange">
                                            Zip Code:
                                          </b>
                                        </label>
                                        <p style={{ color: "black" }}>
                                          {userdata.organizer_pincode}
                                        </p>
                                      </Col>
                                    </Row>
                                  </TabPanel>
                                  <TabPanel value={value} index={2}>
                                    {newline.map((p, index) => (
                                      <>
                                        <Row className="user_data">
                                          <div
                                            style={{
                                              display: "flex",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <p className="provider_containertitle">
                                              Location {index + 1}:
                                            </p>
                                            {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
                                          </div>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Location:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.tour_location}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Country:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.tour_country}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                State:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.tour_state}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                City:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.tour_city}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Ticket:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.ticket_details}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Insurance:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.insurance_details}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Cell No:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.cell_phone}
                                            </p>
                                          </Col>
                                        </Row>

                                        <Row className="user_data">
                                          <div
                                            style={{
                                              display: "flex",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <p className="provider_containertitle">
                                              Flight {index + 1}:
                                            </p>
                                            {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
                                          </div>
                                          <Col md={3}>
                                            {/* <a href={`https://www.google.com/search?q=${p.flight_name}`} target="_blank" className="hyperLink"> */}
                                            <label>
                                              <b>Flight Number:</b>
                                            </label>
                                            {/* </a> */}
                                            <a
                                              href={`https://www.google.com/search?q=${p.flight_name}`}
                                              target="_blank"
                                              className="hyperLink"
                                            >
                                              <p style={{ color: "#2874f0" }}>
                                                {p.flight_name}
                                              </p>
                                            </a>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Departure Time:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.departure_time
                                                ? moment(
                                                    p.departure_time.split(
                                                      "."
                                                    )[0]
                                                  ).format("DD MMM YYYY hh mm")
                                                : null}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Arrival Time:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.arrival_time
                                                ? moment(
                                                    p.arrival_time.split(".")[0]
                                                  ).format("DD MMM YYYY hh mm")
                                                : null}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                departure From:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.departure_from}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Arrival To:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.arrival_to}
                                            </p>
                                          </Col>
                                        </Row>

                                        <Row className="user_data">
                                          <div
                                            style={{
                                              display: "flex",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <p className="provider_containertitle">
                                              Hotel {index + 1}:
                                            </p>
                                            {/* <hr style={{ flex: "1", marginLeft: "20px" }} /> */}
                                          </div>
                                          <Col md={3}>
                                            {/* <a href={`https://www.google.com/search?q=${p.hotel_web_site}`} target="_blank" className="hyperLink"> */}
                                            <label>
                                              <b>Hotel Name:</b>
                                            </label>
                                            {/* </a> */}
                                            <a
                                              href={`https://www.google.com/search?q=${p.hotel_web_site}`}
                                              target="_blank"
                                              style={{ textDecoration: "none" }}
                                              className="hyperLink"
                                            >
                                              <p style={{ color: "#2874f0" }}>
                                                {p.hotel_name}
                                              </p>
                                            </a>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Hotel Rating:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.hotel_rating}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                Country:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.country}
                                            </p>
                                          </Col>
                                          <Col md={3}>
                                            <label>
                                              <b className="fontChange">
                                                State:
                                              </b>
                                            </label>
                                            <p style={{ color: "black" }}>
                                              {p.state}
                                            </p>
                                          </Col>
                                        </Row>
                                      </>
                                    ))}
                                  </TabPanel>
                                </Box>
                              </div>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterNew />
      </div>
    </>
  );
}

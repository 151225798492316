import React, { useState, useEffect } from "react";
import Nav from "./../NavBar/Nav";
import Footer from "./../NavBar/Footer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "./../../api/bootapi";
import { useDispatch, useSelector } from "react-redux";
import { getCategory, selectCategory } from "./../Reducers/categorySlice";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row,
  Button,
} from "reactstrap";
import FooterNew from "../NavBar/FooterNew";

export default function ViewGallery() {
  const [backdropopen, setbackdropopen] = useState(false);
  const [allGallery, setGalleryData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  // const allGallery = useSelector(selectGallery);

  useEffect(() => {
    getAllGallery(1);
  }, []);

  const getAllGallery = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/gallery/get_gallery?id=` + params.gallery_id)
      .then(
        (response) => {
          setGalleryData(response.data.Gallery[0].images);
          setbackdropopen(false);

          // setgetpageno(response.data.total_pages);
          // setTotalTours(response.data.total_count);
          // setcountnumber(value1 - 1);
          // setnumberofElements(response.data.Gallery.length);
          // setSelectedDelete([]);
          // setsearch(false);
          // setbackdropopen(false);
          // document.getElementById("myForm").reset();
        },
        (error) => {
          //   dispatch(getGallery(null));
          setbackdropopen(false);
          console.log(JSON.stringify(error));
        }
      );
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ position: "relative" }}>
        <Nav />
      </div>
      <div className="travel_highlight">
        <Row>
          <Col md={2}>{null}</Col>
          <Col md={10}>
            <div className="file-upload-content">
              {allGallery != null ? (
                allGallery &&
                allGallery.map((p, index) => (
                  // <img src={p.image} style={{float:"right", height:"300px", width:"500px"}} />
                  <table class="field">
                    <tr>
                      <td>
                        <img
                          src={p.image}
                          style={{
                            float: "right",
                            height: "300px",
                            width: "500px",
                            paddingRight: "10px",
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                ))
              ) : (
                <img src="No_image_available.png" width="200" height="200" />
              )}
            </div>
          </Col>
          {/* <Col md={2}>{null}</Col> */}
        </Row>
        {/* <div className="travel_highlightparent">
          <div className="travel_highlightchild">
            <img alt="travel_highlight" src="assets/image/image-015.jpg" />
            <h6>The World on a Shoestring Budget</h6>
            <p>
              Feature your best travel posts so prospective media partners have an
              idea of the kind of work you produce.
            </p>
          </div>
          <div className="travel_highlightchild">
            <img alt="travel_highlight" src="assets/image/image-016.jpg" />
            <h6>Winter Wonderland</h6>
            <p>
              Feature your best travel posts so prospective media partners have an
              idea of the kind of work you produce.
            </p>
          </div>
          <div className="travel_highlightchild">
            <img alt="travel_highlight" src="assets/image/image-017.jpg" />
            <h6>The Road Less Backpacked</h6>
            <p>
              Feature your best travel posts so prospective media partners have an
              idea of the kind of work you produce.
            </p>
          </div>
        </div> */}
      </div>
      <div className="enjoy_the_spirit">
        {/* <div className="enjoy_spiritparent">
          <div className="enjoy_spiritchild">
            <h1>Enjoy the</h1>
            <h1 style={{marginbottom: "20px"}}>Spirit</h1>
            <h3>Travel in the comfort</h3>
            <h3>with an experience</h3>
            <h3>team providing you top</h3>
            <h3>service and grand</h3>
            <h3>hotel, transport and</h3>
            <h3>dining.</h3>
          </div>
          <div className="enjoy_spiritchild">
            <div className="enjoy_spiritright">
              <div className="enjoy_spiritchildleft">
                <img src="assets/image/image-023.jpg" alt="enjoy_spirit" />
              </div>
              <div className="enjoy_spiritchildright">
                <h6>Flight</h6>
                <p>
                  Fly to mekomos hakedoshim with no worries. Linsoa finds and
                  coordinates the best airfare deals and ensures every part of
                  your travel runs smoothly. Have an extraordinarily easy,
                  hassle-free journey!
                </p>
              </div>
            </div>
            <div className="enjoy_spiritright">
              <div className="enjoy_spiritchildleft">
                <img src="assets/image/image-024.png" alt="enjoy_spirit" />
              </div>
              <div className="enjoy_spiritchildright">
                <h6>Lodgings</h6>
                <p>
                  Linsoa provides beautiful, spacious hotels where you will
                  refresh and recharge. All along the journey, through multiple
                  cities and towns, you ll enjoy comfortable accommodations close
                  to the kevorim.
                </p>
              </div>
            </div>
            <div className="enjoy_spiritright">
              <div className="enjoy_spiritchildleft">
                <img src="assets/image/image-022.jpg" alt="enjoy_spirit" />
              </div>
              <div className="enjoy_spiritchildright">
                <h6>Transit</h6>
                <p>
                  Comfortable buses, cars and vans as you travel through Eastern
                  Europe. We provide perfectly coordinated transportation, run
                  like clockwork so you can arrive to each town and kever with
                  energy and spirit.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="contact_detail">
        <FooterNew />
      </div>
    </React.Fragment>
  );
}

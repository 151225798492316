import React, { useState, useEffect } from "react";
import NavNew from "../NavBar/NavNew";
import NavNewDark from "../NavBar/NavNewDark";
import FooterNew from "../NavBar/FooterNew";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, CardBody, Card } from "reactstrap";
import { getTour, selectTour } from "../Reducers/tourSlice";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Swal from "sweetalert2";
import bannerbackground from "../../assets/bannerbackground.jpg";
import { width } from "@mui/system";
import { toast } from "react-toastify";
import Select from "react-select";
import Nav from "../NavBar/Nav";

const PlanATrip = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const formData = new FormData();
  const [viewModal, setViewModal] = useState(false);
  const handleViewModal = () => setViewModal(!viewModal);

  const [backdropopen, setbackdropopen] = useState(false);
  const [tripData, setTripData] = useState();

  const [country1, setcountry] = useState("United States");
  const [countryValid, setCountryValid] = useState(false);

  const [region, setregion] = useState("New York");
  const [regionValid, setRegionValid] = useState(false);

  const [airParticipants, setAirParticipants] = useState(0);
  const [airParticipantStatus, setAirParticipantStatus] = useState(false);
  const [modeStatus, setModeStatus] = useState(false);
  const [nonAirParticipants, setNonAirParticipants] = useState(0);
  const [totalParticipantStatus, setTotalParticipantStatus] = useState(false);

  const [airFarePrice, setAirFarePrice] = useState();
  const [withoutAirFarePrice, setWithoutAirFarePrice] = useState();

  const [tripSeatData, setTripSeatData] = useState([]);
  const [tripSeatData2, setTripSeatData2] = useState([]);

  const [finalParticipants, setFinalParticipants] = useState();
  const [modeOfTour, setModeOfTour] = useState();

  const [paymentMethod, setPaymentMethod] = useState("Card");

  const [imageData, setImageData] = useState();
  const [tourTitle, setTourTitle] = useState([]);

  const [duartion_start_dates, settourStartDate] = useState(null);
  const [duartion_end_dates, settourEndDate] = useState(null);
  const [availableTour, setAvailableTour] = useState(null);
  const [selectedTourId, setSelectedTourId] = useState(null);
  const [selectedTourValue, setSelectedTourValue] = useState(null);
  const [selectedTourStatus, setSelectedTourStatus] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allTour = useSelector(selectTour);
  const [selectedServices, setSelectedServices] = useState({
    transport: false,
    hotel: false,
    flight: false,
    logistic: false,
  });

  const handleCheckboxChange = (service) => {
    setSelectedServices((prevSelectedServices) => {
      const updatedServices = {
        ...prevSelectedServices,
        [service]: !prevSelectedServices[service],
      };

      console.log(updatedServices); // This will log the updated state immediately

      return updatedServices;
    });
  };

  const [f, setf] = useState();

  useEffect(() => {
    getTours();
  }, []);

  const getTours = () => {
    axios.get(`${base_url.api1}/tourapp/trip_list?status=Active`, {}).then(
      (response) => {
        console.log(response.data.tour);
        let tourName = [];
        for (let j = 0; j < response.data.tour.length; j++) {
          let data = {
            value: response.data.tour[j].id,
            label: response.data.tour[j].tour_names,
            startDate: response.data.tour[j].duartion_start_date,
            endDate: response.data.tour[j].duartion_end_date,
          };
          tourName.push(data);
        }
        setAvailableTour(tourName);
      },
      (error) => {
        toast.success(JSON.stringify(error.response.data.message));
        console.log(JSON.stringify(error.response.data.message));
      }
    );
  };

  const onSelect = (selectedList, selectedItem) => {
    console.log(selectedList);
    setSelectedTourId(selectedList.value);
    setSelectedTourValue(selectedList.label);
    settourStartDate(selectedList.startDate);
    settourEndDate(selectedList.endDate);
    setSelectedTourStatus(false);
  };

  const [allYear, setAllYear] = useState();
  const getYear = () => {
    let year_obj = [];
    for (let i = 2000; i < 3000; i++) {
      year_obj.push(i);
    }
    setAllYear(year_obj);
  };

  const onSubmit = (data2) => {
    if (!country1) {
      setCountryValid(true);
      return;
    }
    if (!region) {
      setRegionValid(true);
      return;
    }
    // data2.tour_id = params.tour_Enrollment_id;

    data2.intrested_tour = tourTitle;
    data2.nationality = country1;
    data2.enquiry_status = "Active";
    data2.place_of_birth = country1;
    data2.organizer_state = region;
    data2.user = 1;
    data2.date_of_birth = data2.date_of_birth
      ? moment(data2.date_of_birth).format("YYYY-MM-DD")
      : null;

    let data = {
      tour_id: selectedTourId,
      tour_names: selectedTourValue,
      nationality: data2.nationality,
      place_of_birth: data2.place_of_birth,
      date_of_birth: data2.date_of_birth ? data2.date_of_birth : null,
      first_name: data2.first_name,
      last_name: data2.last_name,
      gender: data2.gender,
      email_address: data2.email_address,
      phone_number: data2.phone_number,
      date_of_birth: data2.date_of_birth ? data2.date_of_birth : null,
      organizer_state: data2.state,
      organizer_city: data2.organizer_city,
      organizer_address: data2.organizer_address,
      organizer_pincode: data2.organizer_pincode,
      transport: selectedServices.transport,
      hotel: selectedServices.hotel,
      flight: selectedServices.flight,
      logistic: selectedServices.logistic,
    };
    let submitData = {
      data: data,
    };

    setbackdropopen(true);
    axios.post(`${base_url.api1}/tourapp/private_trip`, data).then(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          setbackdropopen(false);
          handleViewModal();
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Tour Registered Successfully",
            showCloseButton: true,
            showConfirmButton: false,
          });
          document.getElementById("myForm").reset();
          setcountry("United States");
          setregion("New York");
        } else {
          setbackdropopen(false);
          handleViewModal();
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Payment failed",
            showCloseButton: true,
            showConfirmButton: false,
          });
          document.getElementById("myForm").reset();
          setcountry("United States");
          setregion("New York");
        }
      },
      (error) => {
        setbackdropopen(false);
        handleViewModal();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    );
  };

  const [cardDetails, setCardDetails] = useState({
    card_number: null,
    card_expiry_month: null,
    card_expiry_year: null,
    card_cvv: null,
  });

  const handleChangeCardNumber = (e) => {
    //console.log(e.target.value);
    setCardDetails({
      ...cardDetails,
      card_number: e.target.value,
    });
  };
  console.log(cardDetails);

  const handleChangeExpiryDate = (e) => {
    //console.log(e.target.value);
    setCardDetails({
      ...cardDetails,
      card_expiry_year: e.target.value,
    });
  };

  const handleChangeCVC = (e) => {
    //console.log(e.target.value);
    setCardDetails({
      ...cardDetails,
      card_cvv: e.target.value,
    });
  };

  const [isPersonalOpen, setIsPersonalOpen] = useState(true);

  const personalToggle = () => setIsPersonalOpen(!isPersonalOpen);

  const [isAddressOpen, setIsAddressOpen] = useState(false);

  const addressToggle = () => setIsAddressOpen(!isAddressOpen);

  const [isPaymentOpen, setIsPaymentOpen] = useState(false);

  const paymentToggle = () => setIsPaymentOpen(!isPaymentOpen);

  return (
    <div style={{ backgroundColor: "#F5F6FA" }}>
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />
        {/* <div style={{ position: "relative", height: "25vh" }}>
                    <img
                        src={bannerbackground}
                        alt="Header_Image"
                        style={{ height: "100%", width: "100%" }}
                    />
                    <div className="overlaybg">
                        <div style={{ postion: "absolute" }}>
                            <h1
                                style={{
                                    width: "100%",
                                    marginTop: "200px",
                                    fontSize: "3.5rem",
                                    textAlign: "center",
                                    color: "#fff",
                                    fontFamily: " Caveat,cursive",
                                }}
                            >
                                {tourTitle ? tourTitle : null}
                            </h1>
                        </div>
                    </div>
                </div> */}

        <div className="container">
          <Row className="px-0">
            <Col lg={12} className="carosol_img">
              <form
                id="myForm"
                onSubmit={handleSubmit(onSubmit)}
                className="login_container"
              >
                <p className="tour_head_text_3">Personal Details</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  <div className="col-md-3">
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="First Name"
                        type="text"
                        id="tfirst"
                        {...register("first_name", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.first_name && (
                      <p className="errormessage">First Name is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="tlast"
                        {...register("last_name", {
                          required: true,
                        })}
                        className="form-control"
                        placeholder="Last Name"
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.last_name && (
                      <p className="errormessage">Last Name is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Gender<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <select
                        className="form-control"
                        id="tgender"
                        {...register("gender", {
                          required: true,
                        })}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                      <i className="fa fa-venus designi" />
                    </div>
                    {errors.gender && (
                      <p className="errormessage">Gender is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Email<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="email"
                        id="temail"
                        className="form-control"
                        {...register("email_address", {
                          required: true,
                          pattern:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                        })}
                        placeholder="Email"
                      />
                      <i className="fa fa-envelope-o designi" />
                    </div>
                    {errors.email_address && (
                      <p className="errormessage">Email is Required</p>
                    )}
                  </div>
                </div>
                <div className="my-4 row">
                  <div className="col-md-3">
                    <label>
                      Contact No.<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="tcontact"
                        className="form-control "
                        {...register("phone_number", {
                          required: true,
                        })}
                        placeholder="Contact"
                      />
                      <i className="fa fa-phone designi" />
                    </div>
                    {errors.phone_number && (
                      <p className="errormessage">Phone is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Date Of Birth<span style={{ color: "red" }}></span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="date"
                        id="tdate"
                        className="form-control"
                        {...register("date_of_birth", {
                          required: false,
                        })}
                        placeholder="false"
                      />
                      <i className="fa fa-calendar designi" />
                    </div>
                  </div>
                </div>
                <p className="tour_head_text_3">Address and Passport Details</p>
                <hr style={{ width: "20%" }} />
                <div className="my-4 row">
                  <div className="col-md-3">
                    <label>
                      Country<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <CountryDropdown
                        className="countrydropdown2 form-control underline-input"
                        id="tcountry"
                        defaultOptionLabel="- Select Country -"
                        value={country1}
                        onChange={(val) => {
                          setcountry(val);
                          setCountryValid(false);
                        }}
                      />
                      <i className="fas fa-globe designi" />
                    </div>
                    {countryValid ? (
                      <p className="errormessage">Country is Required</p>
                    ) : null}
                  </div>
                  <div className="col-md-3">
                    <label>
                      State<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <RegionDropdown
                        className="countrydropdown2 form-control underline-input"
                        defaultOptionLabel="- Select State -"
                        id="tstate"
                        country={country1}
                        value={region}
                        onChange={(val) => {
                          setregion(val);
                          setRegionValid(false);
                        }}
                      />
                      <i className="fa fa-map-marker designi" />
                    </div>
                    {regionValid ? (
                      <p className="errormessage">State is Required</p>
                    ) : null}
                  </div>
                  <div className="col-md-3">
                    <label>
                      City<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="tcity"
                        className="form-control underline-input"
                        {...register("organizer_city", { required: true })}
                        placeholder="City"
                      />
                      <i className="fas fa-building designi" />
                    </div>
                    {errors.city && (
                      <p className="errormessage">City is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Address<span style={{ color: "red" }}></span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="taddress"
                        className="form-control underline-input"
                        {...register("organizer_address", {
                          required: true,
                        })}
                        placeholder="Address"
                      />
                      <i className="fa fa-map-marker designi" />
                    </div>
                  </div>
                </div>
                <div className="my-4 row">
                  <div className="col-md-3">
                    <label>
                      Zip Code.<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="tzip"
                        className="form-control underline-input"
                        {...register("organizer_pincode", { required: true })}
                        placeholder="Zip Code"
                        maxLength={6}
                      />
                      <i className="fa fa-tag designi" />
                    </div>
                    {errors.zip && (
                      <p className="errormessage">Zip is Required</p>
                    )}
                  </div>
                </div>
                <p className="tour_head_text_3">Services</p>
                <hr style={{ width: "20%" }} />
                <div className="my-4 row">
                  <div className="col-md-3">
                    <label>
                      Interested Tour Name
                      <span style={{ color: "red" }}>*</span>:
                    </label>
                    <Select options={availableTour} onChange={onSelect} />
                  </div>
                  {selectedTourStatus ? (
                    <p className="errormessage">Intrested Tour is Required</p>
                  ) : null}

                  <div className="col-md-3">
                    <label>
                      Interested Services<span style={{ color: "red" }}>*</span>
                      :
                    </label>
                    <div>
                      {Object.entries(selectedServices).map(
                        ([service, isSelected]) => (
                          <div key={service} className="checkbox-group">
                            <label className="checkbox-label">
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => handleCheckboxChange(service)}
                              />
                              {service.charAt(0).toUpperCase() +
                                service.slice(1)}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div style={{ padding: "0 20px ", marginBottom: "70px" }}>
                  <div>
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <Button
                        style={{
                          padding: "10px 45px",
                          marginLeft: "5px",
                          float: "right",
                          borderRadius: "40px",
                        }}
                        onClick={handleViewModal}
                      >
                        Reset
                      </Button>
                      <Button
                        type="submit"
                        style={{
                          padding: "10px 40px",
                          marginLeft: "5px",
                          float: "right",
                          borderRadius: "40px",
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Col>
          </Row>

          <div className="tourdetails_grid"></div>
        </div>

        <FooterNew />
      </React.Fragment>
    </div>
  );
};

export default PlanATrip;

import React, { useState } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

const GoogleInput = ({
  placeholder,
  setValue,
  currValue,
  stages,
  setStages,
  index,
  field,
}) => {
  const [optionsList, setOptionsList] = useState([]);
  const [isOpenList, setIsOpenList] = useState(false);

  const fetchSuggestions = async (inputValue) => {
    if (!inputValue) {
      setOptionsList([]);
      return;
    }

    try {
      const response = await axios.get(
        `${base_url.api1}/tourapp/address_suggestion?address=${inputValue}`
      );
      const data = response.data;
      const suggestions = data.suggestions.map((suggestion) => ({
        label: suggestion.postal_code
          ? `${suggestion.description},${suggestion.postal_code}`
          : suggestion.description,
        value: suggestion.place_id,
      }));
      setOptionsList(suggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setOptionsList([]);
    }
  };

  const handleAddressInputChange = (e) => {
    fetchSuggestions(e.target.value);
    const newStages = [...stages];
    newStages[index][field] = e.target.value;
    setStages(newStages);
    setValue(e.target.value); // Assuming label is unique and used as field name

    setIsOpenList(true);
  };

  const handleAddressChange = (option) => {
    console.log(option.label);
    const newStages = [...stages];
    newStages[index][field] = option.label;
    setStages(newStages);
    setValue(option.label);
    setOptionsList([]);
    setIsOpenList(false);
  };

  const handleInputBlur = () => {
    console.log("google input value", stages[index].field);
    setTimeout(() => {
      setIsOpenList(false);
    }, 100);
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        <input
          value={currValue}
          onChange={handleAddressInputChange}
          className="search-input form-control"
          type="text"
          autoComplete="off"
          onBlur={handleInputBlur}
          onFocus={() => setIsOpenList(true)} // Open list when input is focused
          id="google_input_icon"
          required
        />
        {/* <i
          className="fa fa-map-marker home_icon"
          style={{ position: "absolute", left: 15, top: 10 }}
        /> */}
        {/* <span
          id="google_input_icon"
          className="home_icon"
          style={{ position: "absolute", left: 15, top: 6 }}
        >
          {field === "from" ? "From:" : "To:"}
        </span> */}
        {isOpenList && optionsList.length > 0 && (
          <ul className="search-results" style={{ width: "300px" }}>
            {optionsList.map((option, index) => (
              <li
                key={index}
                onClick={() => handleAddressChange(option)}
                onMouseDown={(e) => e.preventDefault()}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default GoogleInput;

// src/redux/slices/claimFormSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  date: "",
  clientName: "",
  clientAddress: "",
  passportNumber: "",
  airline: "",
  flightDetails: "",
};

const claimFormSlice = createSlice({
  name: "claimForm",
  initialState,
  reducers: {
    setClaimFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setClaimFormData } = claimFormSlice.actions;

export default claimFormSlice.reducer;

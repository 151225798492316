import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Button, ModalBody } from "reactstrap";
import axios from "axios";
import base_url from "./../../api/bootapi";
import attachmenturl from "./../../api/attachmenturl";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ImageGallery from 'react-image-gallery';

const GalleryViewModal = (props) => {
  const [viewModal, setViewModal] = useState(false);
  const [galleryImages, setGalleryImages] = useState();
  const handleViewModal = () => {
    setViewModal(!viewModal);
    getGalleryImages();
  };
  const handleViewModal2 = () => setViewModal(!viewModal);
  const [allGallery, setGalleryData] = useState();

  const getGalleryImages = (value1) => {
    props.setbackdropopen(true);
    axios.get(`${base_url.api1}/gallery/get_gallery?id=` + props.id).then(
      (response) => {
        let img_obj=[];
        if(response.data.Gallery[0].images){
          for (let i = 0; i < response.data.Gallery[0].images.length; i++) {
            img_obj.push({
              original: attachmenturl+response.data.Gallery[0].images[i].image,
              thumbnail: attachmenturl+response.data.Gallery[0].images[i].image
            })
          }
          console.log(img_obj);
          setGalleryImages(img_obj);
        }
        setGalleryData(response.data.Gallery[0]);
        props.setbackdropopen(false);
      },
      (error) => {
        props.setbackdropopen(false);
        console.log(JSON.stringify(error));
      }
    );
  };

  return (
    <div className="px-2">
      <Button
        color="success"
        style={{ borderRadius: "25px", marginBottom: "10px" }}
        onClick={handleViewModal}
      >
        View Album
      </Button>
      <Modal show={viewModal} onHide={handleViewModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{allGallery?.title} Album</Modal.Title>
        </Modal.Header>
        <Modal.Body className="gallerymodal_carousel">
          {/* {galleryImages?<ImageGallery items={galleryImages} />:null} */}
          <Carousel>
            {allGallery?.images != null ? (
              allGallery?.images &&
              allGallery?.images.map((p, index) => (
                <div>
                  <img
                    className="carosol_img"
                    src={attachmenturl + p.image}
                    width="200"
                    height="500"
                  />
                </div>
              ))
            ) : (
              <h1>Sorry, No Images Available!</h1>
            )}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleViewModal2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GalleryViewModal;

import React, { useState, useEffect } from "react";
import Nav from "./../NavBar/Nav";
import Faq from "./../FAQ/Faq";
import Contact from "./../Contact/Contact";
import axios from "axios";
import base_url from "../../api/bootapi";
import Footer from "./../NavBar/Footer";
import { Col, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "./../Carousel/Carousel";
import Parser from "html-react-parser";
import FooterNew from "../NavBar/FooterNew";
toast.configure();

export default function Home() {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const [selectShow, setSelectedShow] = useState(3);

  const [bannerTitle, setBannerTittle] = useState("");
  const [bannerCaption, setBannerCaption] = useState("");
  const [bannerImage, setBannerImg] = useState("");

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (window.innerWidth <= 1200 && window.innerWidth > 770) {
      setSelectedShow(2);
    } else if (window.innerWidth <= 770) {
      setSelectedShow(1);
    } else {
      setSelectedShow(3);
    }
  }, []);
  useEffect(() => {
    window.onresize = function () {
      // console.log(window.innerHeight);
      console.log(window.innerWidth);
      if (window.innerWidth <= 1200 && window.innerWidth > 770) {
        setSelectedShow(2);
      } else if (window.innerWidth <= 770) {
        setSelectedShow(1);
      } else {
        setSelectedShow(3);
      }
    };
    // if(width <= 1000){
    //   setSelectedShow(2)
    // }
  }, [window.innerWidth]);

  const getData = () => {
    axios
      .get(
        `${base_url.api1}/cmsapp/cms?id=21` //Live
        // `${base_url.api1}/cmsapp/cms?id=42` //Test
      )
      .then(
        (response) => {
          console.log(response.data.Cms);
          setBannerTittle(response.data.Cms.title);
          setBannerCaption(response.data.Cms.caption);
          setBannerImg(response.data.Cms.image);
        },
        (error) => {
          console.log(JSON.stringify(error));
        }
      );
  };

  return (
    <React.Fragment>
      <Nav />
      <div style={{ position: "relative", height: "80vh" }}>
        <img
          src={`${base_url.api1}/${bannerImage}`}
          alt="Header_Image"
          style={{ height: "100%", width: "100%" }}
        />
        <div className="overlaybg">
          <div className="main_header_detail">
            <h1>{bannerTitle}</h1>
            <div className="bannerCaption hello">{Parser(bannerCaption)}</div>
            {/* <p className="main_header_detail_p">
            Rabbi Elimelech of Lizhensk. Rabbi Nachman of Uman.
            <br/>The Baal Shem Tov. Find yourself here—with your
            <br/>perfectly-planned trip by Linsoa Tours. Fill your spirit.
            <br/>We’ll handle the rest.For those bound to our past, it’s the ultimate present.
          </p> */}
            {/* <h3>The Planning is Ours.  The Journey is Yours.</h3> */}
          </div>
        </div>
      </div>

      {/* <div style={{ position: "relative" }}>
        <div className="hero-main" style={{marginTop:"-30px",maxHeight:"700px"}} id="logo">
          <div className="hero-main-img" style={{height:"100%"}}>
            <img src={`https://apilinsoatours.odisoft.in/${bannerImage}`} alt="Header_Image" />
          </div>
        </div>
        <Nav />
        <div className="main_header_detail">
          <h1>{bannerTitle}</h1>
          <h2>{bannerCaption}</h2>
          <p className="main_header_detail_p">
            Rabbi Elimelech of Lizhensk. Rabbi Nachman of Uman.
            <br/>The Baal Shem Tov. Find yourself here—with your
            <br/>perfectly-planned trip by Linsoa Tours. Fill your spirit.
            <br/>We’ll handle the rest.For those bound to our past, it’s the ultimate present.
          </p>
          <h3>The Planning is Ours.  The Journey is Yours.</h3>
        </div>
      </div> */}

      {/* <!-- ***** About Avontur Travel Start ***** --> */}
      <section
        className="section"
        id="about_2"
        style={{ padding: "40px 0px 120px 0px" }}
      >
        <div className="containers">
          <div className="row">
            <ul>
              <li>
                <div className="left-heading col-6">
                  <h6>ABOUT AVONTUR TRAVEL</h6>
                  <h3 className="poppins_font" style={{ fontSize: "33px" }}>
                    <b>
                      We Provide The Best
                      <br /> Experience for You to
                      <br /> Explore Nature.
                    </b>
                  </h3>
                  <p className="poppins_font">
                    Work with our influencer to help create unique <br />
                    content for your brand, gather testimonials and <br />
                    generate organic word of mouth traffic
                  </p>
                  <a className="main-button-slider">Read More</a>
                </div>
              </li>
              <li>
                <div class="col-6">
                  <img
                    src="assets/image/sliced/home/home-welcome.jpg"
                    className="rounded img-fluid d-block mx-auto"
                    alt="App"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- ***** About Avontur Travel End***** --> */}

      {/* <!-- ***** Carousel Start***** --> */}
      <section className="section" style={{ background: "#f5f6fa" }}>
        <div className="hero-main homecarousel_image" id="logo">
          <div className="hero-main-img_3">
            <img
              src="assets/image/sliced/home/star-baloon-bg.jpg"
              alt="Header_Image"
            />
          </div>
        </div>
        <div className="container">
          <div className="text-center">
            <h1 className="tour_head_2">Enjoy the Spirit</h1>
            <p className="tour_head_text_2">
              Travel in the confort with an experience team providing you top
              <br />
              service and grand hotels, transport and dining.
            </p>
          </div>
          <Carousel show={selectShow} infiniteLoop={false}>
            <div className="item">
              <div className="thumb-wrapper">
                <div className="img-box">
                  <img
                    src="assets/image/sliced/home/flight.jpeg"
                    className="img-responsive"
                    style={{ height: "184px" }}
                    alt=""
                  />
                </div>
                <div className="thumb-content">
                  <h4>Flight</h4>
                  <p>
                    Fly to mekomos hakedoshim with no worries. Linsoa Tours
                    finds and coordinates the best air
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="thumb-wrapper">
                <div className="img-box">
                  <img
                    src="assets/image/sliced/home/lodgings.jpeg"
                    className="img-responsive"
                    style={{ height: "184px" }}
                    alt=""
                  />
                </div>
                <div className="thumb-content">
                  <h4>Lodgings</h4>
                  <p>
                    Linsoa Tours provides beautiful, spacious hotels where you
                    will refresh and recharge. All along
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="thumb-wrapper">
                <div className="img-box">
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "184px" }}
                    alt=""
                  />
                </div>
                <div className="thumb-content">
                  <h4>Transit</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="thumb-wrapper">
                <div className="img-box">
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "184px" }}
                    alt=""
                  />
                </div>
                <div className="thumb-content">
                  <h4>USA</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="thumb-wrapper">
                <div className="img-box">
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "184px" }}
                    alt=""
                  />
                </div>
                <div className="thumb-content">
                  <h4>France</h4>
                  <p>
                    Vivamus fermentum in arcu in aliquam. Quisque aliqua porta
                    odio in fringilla vivamus.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="thumb-wrapper">
                <div className="img-box">
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "184px" }}
                    alt=""
                  />
                </div>
                <div className="thumb-content">
                  <h4>Poland</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="thumb-wrapper">
                <div className="img-box">
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "184px" }}
                    alt=""
                  />
                </div>
                <div className="thumb-content">
                  <h4>Canada</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="thumb-wrapper">
                <div className="img-box">
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "184px" }}
                    alt=""
                  />
                </div>
                <div className="thumb-content">
                  <h4>UK</h4>
                  <p>
                    Vivamus fermentum in arcu in aliquam. Quisque aliqua porta
                    odio in fringilla vivamus.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="thumb-wrapper">
                <div className="img-box">
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "184px" }}
                    alt=""
                  />
                </div>
                <div className="thumb-content">
                  <h4>Ireland</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </section>
      {/* <!-- ***** Carousel End***** --> */}

      {/* <!-- ***** Hand Picked Tours Start ***** --> */}
      <div className="hero-main" id="logo">
        <div className="hero-main-img_4">
          <img
            src="assets/image/sliced/home/cloud-bg-bottom.jpg"
            alt="Header_Image"
          />
        </div>
      </div>
      <div className="text-center hand">
        <div className="row mx-0">
          {/* <ul className="flex_url7"> */}
          <div className="col-md-4">
            <div className="icon">
              <i>
                <img src="assets/image/sliced/home/home-mid-icon1.png" alt="" />
              </i>
            </div>
            <h5 className="service-title" style={{ fontSize: "25px" }}>
              Hand Picked Tours
            </h5>
            <p>
              Aenean vulputate massa sed neque consectetur, ac fringilla quam
              aliquet. Sed a enim nec eros tempor cursus at id libero.
            </p>
            <a href="/" className="main-button-slider_3">
              Read More
            </a>
          </div>
          <div className="col-md-4">
            <div className="icon margin_top">
              <i>
                <img src="assets/image/sliced/home/home-mid-icon2.png" alt="" />
              </i>
            </div>
            <h5 className="service-title_1" style={{ fontSize: "25px" }}>
              Expert Local Guides
            </h5>
            <p>
              Aenean vulputate massa sed neque consectetur, ac fringilla quam
              aliquet. Sed a enim nec eros tempor cursus at id libero.
            </p>
            <a href="/" className="main-button-slider_4">
              Read More
            </a>
          </div>
          <div className="col-md-4">
            <div className="icon margin_top">
              <i>
                <img src="assets/image/sliced/home/home-mid-icon3.png" alt="" />
              </i>
            </div>
            <h5 className="service-title_2" style={{ fontSize: "25px" }}>
              Best Price Guarantee
            </h5>
            <p>
              Aenean vulputate massa sed neque consectetur, ac fringilla quam
              aliquet. Sed a enim nec eros tempor cursus at id libero.
            </p>
            <a href="/" className="main-button-slider_2">
              Read More
            </a>
          </div>
        </div>
      </div>
      {/* <!-- ***** Hand Picked Tours End ***** --> */}

      {/* <!-- ***** Private&Vip Start ***** --> */}
      <section className="section" id="about_3">
        <div style={{ position: "relative" }}>
          <div className="hero-main_2" id="logo">
            <div className="hero-main-img_2">
              <img
                src="assets/image/sliced/home/private.jpg"
                alt="Header_Image"
              />
            </div>
          </div>
          <div className="main_header_detail_2">
            <div className="left-text col-md-12 col-sm-12 mobile-bottom-fix main_header_detail_text">
              <div className="left-heading">
                <h6 className="main_header_detail_h6">
                  Private &<br /> VIP Tours
                </h6>
              </div>
              <p className="poppins_font">
                Your dream trip, full of incredible experiences and glorious
                discoveries, with VIP service and five star accommodations and
                features.
              </p>
              <a className="main-button-slider_2">Read More</a>
              <div style={{ padding: "10px" }}></div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** Private&Vip End***** --> */}

      {/* <!-- ***** Gallery Start ***** --> */}
      <div className="text-center container">
        <div className="row mx-0 p-0">
          {/* <div className="flex_url4"> */}
          <div className="col-md-4 p-0">
            <img
              src="assets/image/sliced/home/home-gallery-1.jpg"
              className="img-fluid w-100"
            />
          </div>
          <div className="col-md-4 p-0">
            <img
              src="assets/image/sliced/home/home-gallery-3.jpg"
              className="img-fluid w-100"
            />
          </div>
          <div className="col-md-4 p-0">
            <img
              src="assets/image/sliced/home/home-gallery-5.jpg"
              className="img-fluid w-100"
            />
          </div>
        </div>
        <div className="row mx-0 p-0" style={{ marginTop: "-2%" }}>
          {/* <div className="flex_url4" style={{ marginTop: "-2%" }}> */}
          <div className="col-md-4 p-0">
            <img
              src="assets/image/sliced/home/home-gallery-2.jpg"
              className="img-fluid w-100"
            />
          </div>
          <div className="col-md-4 p-0">
            <img
              src="assets/image/sliced/home/home-gallery-4.jpg"
              className="img-fluid w-100"
            />
          </div>
          <div className="col-md-4 p-0">
            <img
              src="assets/image/sliced/home/home-gallery-6.jpg"
              className="img-fluid w-100"
            />
          </div>
        </div>
      </div>
      <div style={{ padding: "5%" }}></div>
      {/* <!-- *****Gallery End ***** --> */}

      {/* <!-- ***** Faq Start & Contact  ***** --> */}
      <section className="section" id="about_4">
        <div className="container" style={{ maxWidth: "100%" }}>
          <div className="row mx-0">
            {/* <ul className="flex_url5"> */}
            <div className="col-lg-8">
              <div className="left-text col-md-12 col-sm-12 mobile-bottom-fix">
                <div className="left-heading">
                  <h5>FAQ</h5>
                </div>
                <Faq />
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="right-image col-md-12 col-sm-12 mobile-bottom-fix-big"
                data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
              >
                <div
                  className="opc"
                  style={{
                    backgroundcolor: "rgb(255, 255, 255)",
                    opacity: "1",
                    color: "rgb(255, 255, 255)",
                    // width: "500px",
                    // height: "500px",
                  }}
                >
                  <Contact />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** Faq & Contact End ***** --> */}

      {/* <!-- *****Logo Start ***** --> */}
      <div style={{ paddingTop: "3%" }}></div>
      <section className="section">
        <div className="text-center logo_imgs">
          <div className="row mx-0">
            <div className="col-md">
              <img
                src="assets/image/sliced/home/home-client-logo1.jpg"
                className="img-fluid"
              />
            </div>
            <div className="col-md">
              <img
                src="assets/image/sliced/home/home-client-logo2.jpg"
                className="img-fluid"
              />
            </div>
            <div className="col-md">
              <img
                src="assets/image/sliced/home/home-client-logo3.jpg"
                className="img-fluid"
              />
            </div>
            <div className="col-md">
              <img
                src="assets/image/sliced/home/home-client-logo4.jpg"
                className="img-fluid"
              />
            </div>
            <div className="col-md">
              <img
                src="assets/image/sliced/home/home-client-logo5.jpg"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <div style={{ paddingTop: "3%" }}></div>
      {/* <!-- *****Logo End ***** --> */}

      <FooterNew />
    </React.Fragment>
  );
}

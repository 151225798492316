import React, { useState, useEffect } from "react";
import NavNewDark from "../NavBar/NavNewDark";
import NavNew from "../NavBar/NavNew";
import FooterNew from "../NavBar/FooterNew";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, CardBody, Card } from "reactstrap";
import { getTour, selectTour } from "../Reducers/tourSlice";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Swal from "sweetalert2";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import bannerbackground from "../../assets/bannerbackground.jpg";
import { width } from "@mui/system";
import Nav from "../NavBar/Nav";

const TourTripEnrollment = () => {
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  const formData = new FormData();
  const [viewModal, setViewModal] = useState(false);
  const handleViewModal = () => setViewModal(!viewModal);

  const [backdropopen, setbackdropopen] = useState(false);
  const [tripData, setTripData] = useState();

  const [country1, setcountry] = useState("United States");
  const [countryValid, setCountryValid] = useState(false);

  const [region, setregion] = useState("New York");
  const [regionValid, setRegionValid] = useState(false);

  const [airParticipants, setAirParticipants] = useState(0);
  const [airParticipantStatus, setAirParticipantStatus] = useState(false);
  const [modeStatus, setModeStatus] = useState(false);
  const [nonAirParticipants, setNonAirParticipants] = useState(0);
  const [totalParticipantStatus, setTotalParticipantStatus] = useState(false);

  const [airFarePrice, setAirFarePrice] = useState();
  const [withoutAirFarePrice, setWithoutAirFarePrice] = useState();

  const [tripSeatData, setTripSeatData] = useState([]);
  const [tripSeatData2, setTripSeatData2] = useState([]);

  const [finalParticipants, setFinalParticipants] = useState();
  const [modeOfTour, setModeOfTour] = useState();

  const [paymentMethod, setPaymentMethod] = useState("Card");

  const [imageData, setImageData] = useState();
  const [tourTitle, setTourTitle] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allTour = useSelector(selectTour);

  const [f, setf] = useState();
  const location = useLocation();
  const tour_names = location.state?.tour_names || null;
  const [upcomingTourName, setupcomingTourName] = useState();

  console.log("84", tour_names);

  useEffect(() => {
    getTourByTourId(params.tour_Enrollment_id);
    getImagesByTourId(params.tour_Enrollment_id);
    getAllTourList();
  }, [params.tour_Enrollment_id]);

  const getAllTourList = () => {
    axios.get(`${base_url.api1}/tourapp/trip_list?status=Active`, {}).then(
      (response) => {
        console.log("100", response.data.tour);
        // setupcomingTourName(response.data.tour);
        document.getElementById("myForm").reset();
        // Find the object with matching id
        const matchingObject = response?.data?.tour?.find(
          (obj) => obj.id == params?.tour_Enrollment_id
        );

        // Check if a matching object was found
        if (matchingObject) {
          console.log("Matching tour_names:", matchingObject.tour_names);
          setupcomingTourName(matchingObject.tour_names);
        } else {
          console.log(
            "No matching object found for the given tour_Enrollment_id"
          );
        }
      },
      (error) => {
        setupcomingTourName(null);
        console.log(JSON.stringify(error.response.data));
      }
    );
  };

  const getTourByTourId = (value1) => {
    setbackdropopen(true);
    axios
      .get(`${base_url.api1}/tourapp/get_tour?id=` + params.tour_Enrollment_id)
      .then(
        (response) => {
          setTourTitle(response.data.Tour[0].tour_names);
          setTripData(response.data.Tour[0]);
          let remaining_seats =
            response.data.Tour[0].total_seats -
            response.data.Tour[0].booked_seat;
          setAirFarePrice(
            response.data.Tour[0].with_airfare_price.match(/\d+/)[0]
          );
          setWithoutAirFarePrice(
            response.data.Tour[0].with_out_airfare_price.match(/\d+/)[0]
          );
          setFinalParticipants(remaining_seats);
          let remaining_obj = [];
          for (let i = 1; i <= remaining_seats; i++) {
            remaining_obj.push(i);
          }
          setTripSeatData(remaining_obj);
          setTripSeatData2(remaining_obj);
          // dispatch(getTour(response.data.Tour));
          setbackdropopen(false);
        },
        (error) => {
          // dispatch(getTour(null));
          setTourTitle();
          setbackdropopen(false);
          console.log(JSON.stringify(error));
        }
      );
  };

  // const handleChangeAir = (e) =>{
  //   let remaining_data = finalParticipants-e.target.value;
  //   let remaining_obj = [];
  //   for (let i = 1; i <= remaining_data; i++) {
  //     remaining_obj.push(i);
  //   }
  //   setTripSeatData2(remaining_obj);
  // }

  // const handleChangeNonAir = (e) =>{
  //   let remaining_data = finalParticipants-e.target.value;
  //   let remaining_obj = [];
  //   for (let i = 1; i <= remaining_data; i++) {
  //     remaining_obj.push(i);
  //   }
  //   setTripSeatData(remaining_obj);
  // }

  const [allYear, setAllYear] = useState();
  const getYear = () => {
    let year_obj = [];
    for (let i = 2000; i < 3000; i++) {
      year_obj.push(i);
    }
    setAllYear(year_obj);
  };

  const getImagesByTourId = (value1) => {
    setbackdropopen(true);
    axios
      .get(
        `${base_url.api1}/tourapp/get_image?tour=` + params.tour_Enrollment_id
      )
      .then(
        (response) => {
          setImageData(response.data.Gallery);
          setbackdropopen(false);
        },
        (error) => {
          setImageData();
          setbackdropopen(false);
          console.log(JSON.stringify(error));
        }
      );
  };

  const onSubmit = (data2) => {
    if (!country1) {
      setCountryValid(true);
      return;
    }
    if (!region) {
      setRegionValid(true);
      return;
    }
    data2.tour_id = params.tour_Enrollment_id;
    data2.trip_start_date = tripData?.duartion_start_date;
    data2.trip_end_date = tripData?.duartion_end_date;
    data2.mode_of_tour = modeOfTour;
    data2.number_of_participants_with_airfare = "1";
    // data2.total_price =
    //     airParticipants * airFarePrice + nonAirParticipants * airFarePrice;
    data2.intrested_tour = upcomingTourName;
    data2.nationality = country1;
    data2.enquiry_status = "Active";
    data2.place_of_birth = country1;
    data2.state = region;
    data2.user = 1;
    data2.date_of_birth = data2.date_of_birth
      ? moment(data2.date_of_birth).format("YYYY-MM-DDThh:mm:ss")
      : null;
    data2.passport_expiration_date = moment(
      data2.passport_expiration_date
    ).format("YYYY-MM-DDThh:mm:ss");
    data2.trip_start_date = moment(data2.trip_start_date).format(
      "YYYY-MM-DDThh:mm:ss"
    );
    data2.trip_end_date = moment(data2.trip_end_date).format(
      "YYYY-MM-DDThh:mm:ss"
    );

    let data = {
      tour_id: params.tour_Enrollment_id,
      trip_start_date: data2.trip_start_date,
      trip_end_date: data2.trip_end_date,
      mode_of_tour: data2.mode_of_tour,
      number_of_participants_with_airfare: "1",
      total_price: 100,
      intrested_tour: upcomingTourName,
      nationality: data2.nationality,
      enquiry_status: data2.enquiry_status,
      place_of_birth: data2.place_of_birth,
      state: data2.state,
      user: data2.user,
      date_of_birth: data2.date_of_birth ? data2.date_of_birth : null,
      passport_expiration_date: data2.passport_expiration_date,
      trip_start_date: data2.trip_start_date,
      trip_end_date: data2.trip_end_date,
      first_name: data2.first_name,
      last_name: data2.last_name,
      gender: data2.gender,
      email_address: data2.email_address,
      phone_number: data2.phone_number,
      date_of_birth: data2.date_of_birth ? data2.date_of_birth : null,
      city: data2.city,
      address: data2.address,
      zip: data2.zip,
      passport_number: data2.passport_number,
      payment_method: data2.payment_method,
      card_holder_name: data2.card_holder_name,
      card_expiry_month: cardDetails.card_expiry_year.substring(0, 2),
      card_expiry_year: cardDetails.card_expiry_year.substring(5, 7),
      number_of_participants_with_out_airfare: "0",
    };
    let submitData = {
      data: data,
      card_number: cardDetails.card_number,
      card_cvv: cardDetails.card_cvv,
    };
    console.log(data);
    formData.append("data", JSON.stringify(submitData));

    setbackdropopen(true);
    axios.post(`${base_url.api1}/tourapp/trip_enquiry`, formData).then(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          setbackdropopen(false);
          handleViewModal();
          // Swal.fire({
          //     position: "center",
          //     icon: "success",
          //     title: "Payment Successful",
          //     showCloseButton: true,
          //     showConfirmButton: false,
          // });
          navigate("/thank-you-page");
          document.getElementById("myForm").reset();
          setcountry("United States");
          setregion("New York");
        } else {
          setbackdropopen(false);
          handleViewModal();
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Payment failed",
            showCloseButton: true,
            showConfirmButton: false,
          });
          setcountry("United States");
          setregion("New York");
        }
      },
      (error) => {
        setbackdropopen(false);
        handleViewModal();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    );
  };

  const [cardDetails, setCardDetails] = useState({
    card_number: null,
    card_expiry_month: null,
    card_expiry_year: null,
    card_cvv: null,
  });

  const handleChangeCardNumber = (e) => {
    //console.log(e.target.value);
    setCardDetails({
      ...cardDetails,
      card_number: e.target.value,
    });
  };
  console.log(cardDetails);

  const handleChangeExpiryDate = (e) => {
    //console.log(e.target.value);
    setCardDetails({
      ...cardDetails,
      card_expiry_year: e.target.value,
    });
  };

  const handleChangeCVC = (e) => {
    //console.log(e.target.value);
    setCardDetails({
      ...cardDetails,
      card_cvv: e.target.value,
    });
  };

  const [isPersonalOpen, setIsPersonalOpen] = useState(true);

  const personalToggle = () => setIsPersonalOpen(!isPersonalOpen);

  const [isAddressOpen, setIsAddressOpen] = useState(false);

  const addressToggle = () => setIsAddressOpen(!isAddressOpen);

  const [isPaymentOpen, setIsPaymentOpen] = useState(false);

  const paymentToggle = () => setIsPaymentOpen(!isPaymentOpen);

  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <div style={{ backgroundColor: "#F5F6FA" }}>
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />
        {/* <div style={{ position: "relative", height: "25vh" }}>
          <img
            src={bannerbackground}
            alt="Header_Image"
            style={{ height: "100%", width: "100%" }}
          />
          <div className="overlaybg">
            <div style={{ postion: "absolute" }}>
              <h1
                style={{
                  width: "100%",
                  marginTop: "200px",
                  fontSize: "3.5rem",
                  textAlign: "center",
                  color: "#fff",
                  fontFamily: " Caveat,cursive",
                }}
              >
                {tourTitle ? tourTitle : null}
              </h1>
            </div>
          </div>
        </div> */}

        <div className="container">
          <Row className="px-0">
            <Col lg={12} className="carosol_img">
              <form
                id="myForm"
                onSubmit={handleSubmit(onSubmit)}
                className="login_container"
              >
                <p className="tour_head_text_3">Personal Details</p>
                <hr style={{ width: "20%" }} />
                <div className="row">
                  <div className="col-md-3">
                    <label>
                      First Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        className="form-control"
                        placeholder="First Name"
                        type="text"
                        id="tfirst"
                        {...register("first_name", {
                          required: true,
                        })}
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.first_name && (
                      <p className="errormessage">First Name is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="tlast"
                        {...register("last_name", {
                          required: true,
                        })}
                        className="form-control"
                        placeholder="Last Name"
                      />
                      <i className="fa fa-user-o designi" />
                    </div>
                    {errors.last_name && (
                      <p className="errormessage">Last Name is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Gender<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <select
                        className="form-control"
                        id="tgender"
                        {...register("gender", {
                          required: true,
                        })}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                      <i className="fa fa-venus designi" />
                    </div>
                    {errors.gender && (
                      <p className="errormessage">Gender is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Email<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="email"
                        id="temail"
                        className="form-control"
                        {...register("email_address", {
                          required: true,
                          pattern:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                        })}
                        placeholder="Email"
                      />
                      <i className="fa fa-envelope-o designi" />
                    </div>
                    {errors.email_address && (
                      <p className="errormessage">Email is Required</p>
                    )}
                  </div>
                </div>
                <div className="my-4 row">
                  <div className="col-md-3">
                    <label>
                      Contact No.<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="tcontact"
                        className="form-control "
                        {...register("phone_number", {
                          required: true,
                        })}
                        placeholder="Contact"
                      />
                      <i className="fa fa-phone designi" />
                    </div>
                    {errors.phone_number && (
                      <p className="errormessage">Phone is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Date Of Birth<span style={{ color: "red" }}></span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="date"
                        id="tdate"
                        className="form-control"
                        {...register("date_of_birth", {
                          required: false,
                        })}
                        placeholder="DOB"
                      />
                      <i className="fa fa-calendar designi" />
                    </div>
                  </div>
                </div>
                <p className="tour_head_text_3">Address and Passport Details</p>
                <hr style={{ width: "20%" }} />
                <div className="my-4 row">
                  <div className="col-md-3">
                    <label>
                      Country<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <CountryDropdown
                        className="countrydropdown2 form-control underline-input"
                        id="tcountry"
                        defaultOptionLabel="- Select Country -"
                        value={country1}
                        onChange={(val) => {
                          setcountry(val);
                          setCountryValid(false);
                        }}
                      />
                      <i className="fas fa-globe designi" />
                    </div>
                    {countryValid ? (
                      <p className="errormessage">Country is Required</p>
                    ) : null}
                  </div>
                  <div className="col-md-3">
                    <label>
                      State<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <RegionDropdown
                        className="countrydropdown2 form-control underline-input"
                        defaultOptionLabel="- Select State -"
                        id="tstate"
                        country={country1}
                        value={region}
                        onChange={(val) => {
                          setregion(val);
                          setRegionValid(false);
                        }}
                      />
                      <i className="fa fa-map-marker designi" />
                    </div>
                    {regionValid ? (
                      <p className="errormessage">State is Required</p>
                    ) : null}
                  </div>
                  <div className="col-md-3">
                    <label>
                      City<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="tcity"
                        className="form-control underline-input"
                        {...register("city", { required: true })}
                        placeholder="City"
                      />
                      <i className="fas fa-building designi" />
                    </div>
                    {errors.city && (
                      <p className="errormessage">City is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Address<span style={{ color: "red" }}></span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="taddress"
                        className="form-control underline-input"
                        {...register("address", {
                          required: true,
                        })}
                        placeholder="Address"
                      />
                      <i className="fa fa-map-marker designi" />
                    </div>
                  </div>
                </div>
                <div className="my-4 row">
                  <div className="col-md-3">
                    <label>
                      Zip Code.<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="tzip"
                        className="form-control underline-input"
                        {...register("zip", { required: true })}
                        placeholder="Zip Code"
                        maxLength={6}
                      />
                      <i className="fa fa-tag designi" />
                    </div>
                    {errors.zip && (
                      <p className="errormessage">Zip is Required</p>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label>
                      Passport number<span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        id="tpassport"
                        className="form-control underline-input"
                        {...register("passport_number", {
                          required: true,
                        })}
                        placeholder="Passport Number"
                      />
                      <i className="fa fa-id-card designi" />
                    </div>
                    {errors.passport_number && (
                      <p className="errormessage">Passport is Required</p>
                    )}
                  </div>

                  <div className="col-md-3">
                    <label>
                      Passport Expiration Date
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="date"
                        id="texpire"
                        className="form-control underline-input"
                        {...register("passport_expiration_date", {
                          required: true,
                        })}
                        placeholder="Passport Expiration Date"
                        min={getCurrentDate()}
                      />
                      <i className="fa fa-calendar-times designi" />
                    </div>
                    {errors.passport_expiration_date && (
                      <p className="errormessage">
                        Passport Expiry Date is Required
                      </p>
                    )}
                  </div>
                </div>
                <p className="tour_head_text_3">Payment</p>
                <hr style={{ width: "20%" }} />
                <div className="form-group creatadmin2">
                  <Row>
                    <Col md={3}>
                      <label>
                        Payment Method
                        <span style={{ color: "red" }}>*</span>:
                      </label>
                      <select
                        className="form-control"
                        {...register("payment_method", {
                          required: true,
                        })}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <option value="Card">Card</option>
                        <option value="Check">Check</option>
                        <option value="Cash">Cash</option>
                        <option value="Zelle">Zelle</option>
                      </select>
                      <i className="fa fa-google-wallet designi" />
                      {errors.payment_method && (
                        <p className="errormessage">
                          Payment Method is Required
                        </p>
                      )}
                    </Col>
                  </Row>
                </div>

                {paymentMethod == "Card" ? (
                  <>
                    <div className="form-group creatadmin2">
                      <Row>
                        <Col md={3}>
                          <label>
                            Card Holder Name
                            <span style={{ color: "red" }}>*</span>:
                          </label>
                          <input
                            type="text"
                            className="form-control underline-input"
                            {...register("card_holder_name", {
                              required: (value) => {
                                if (paymentMethod == "Card") {
                                  return true;
                                }
                              },
                            })}
                          />
                          <i className="fa fa-user designi" />
                          {errors.card_holder_name && (
                            <p className="errormessage">
                              Card Holder Name is Required
                            </p>
                          )}
                        </Col>
                        <Col md={7} style={{ marginTop: "22px" }}>
                          <PaymentInputsWrapper {...wrapperProps}>
                            <svg {...getCardImageProps({ images })} />
                            <input
                              {...getCardNumberProps({
                                onChange: handleChangeCardNumber,
                              })}
                            />
                            <input
                              {...getExpiryDateProps({
                                onChange: handleChangeExpiryDate,
                              })}
                            />
                            <input
                              {...getCVCProps({
                                onChange: handleChangeCVC,
                              })}
                            />
                          </PaymentInputsWrapper>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : null}

                <div style={{ padding: "0 20px ", marginBottom: "50px" }}>
                  <div>
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        padding: "5px",
                      }}
                    >
                      <Button
                        style={{
                          padding: "10px 45px",
                          marginLeft: "5px",
                          float: "right",
                          borderRadius: "40px",
                        }}
                        color="primary"
                        onClick={handleViewModal}
                      >
                        Reset
                      </Button>
                      <Button
                        type="submit"
                        style={{
                          padding: "10px 40px",
                          marginLeft: "5px",
                          float: "right",
                          borderRadius: "40px",
                        }}
                        color="primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </Col>
          </Row>

          <div className="tourdetails_grid"></div>
        </div>

        <FooterNew />
      </React.Fragment>
    </div>
  );
};

export default TourTripEnrollment;

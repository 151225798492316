import React, { useState } from "react";
import Nav from "../NavBar/Nav";
import FooterNew from "../NavBar/FooterNew";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import base_url from "../../api/bootapi";
import axios from "axios";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

function VehicleBookingStatus() {
  const [userId, setUserId] = useState("");
  const [bookings, setBookings] = useState([]);
  const [backdropopen, setbackdropopen] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, "");
    setUserId(numericValue);
  };

  const handleCheckStatus = () => {
    if (!userId) {
      return;
    }
    setbackdropopen(true);

    axios
      .get(
        `${base_url.api1}/tourapp/vehicle_booking_request?booking_contact=${userId}`
      )
      .then((response) => {
        setbackdropopen(false);
        setBookings(response?.data?.booking || []);
        setUserId("");
      })
      .catch((error) => {
        setbackdropopen(false);
        Swal.fire({
          icon: "error",
          title: "No Record Found",
          html: `No record found for Booking ID:<strong>${userId}</strong>`,
          confirmButtonText: "Ok",
        });
        setUserId("");
      });
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Nav />

      <div style={{ margin: "85px 0" }}>
        <div className="container mt-4">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card shadow">
                <div className="card-body" style={{ minHeight: "400px" }}>
                  <h5
                    className="card-title text-center mb-4"
                    style={{
                      fontFamily: "Arial, sans-serif",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#007bff",
                    }}
                  >
                    Check Your Booking Status
                  </h5>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Booking ID"
                      value={userId}
                      onChange={handleInputChange}
                      style={{
                        borderRadius: "20px 0 0 20px",
                        borderColor: "#007bff",
                      }}
                    />
                    <div className="input-group-append">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={handleCheckStatus}
                        style={{ borderRadius: "0 20px 20px 0" }}
                      >
                        Check Status
                      </Button>
                    </div>
                  </div>

                  {bookings.length > 0 && (
                    <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                      {bookings
                        .sort(
                          (a, b) =>
                            new Date(b.created_date) - new Date(a.created_date)
                        )
                        .map((booking) => {
                          const startTrip =
                            booking.vehicle_itinerary.length > 0
                              ? booking.vehicle_itinerary[0]
                              : null;
                          const endTrip =
                            booking.vehicle_itinerary.length > 0
                              ? booking.vehicle_itinerary[
                                  booking.vehicle_itinerary.length - 1
                                ]
                              : null;

                          return (
                            <div key={booking.id} className="card mt-3">
                              <div className="card-body">
                                <h6
                                  className="card-title"
                                  style={{
                                    fontFamily: "Arial, sans-serif",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Booking Date:{" "}
                                  {new Date(
                                    booking.created_date
                                  ).toLocaleString()}
                                </h6>
                                {startTrip && (
                                  <p
                                    className="card-text"
                                    style={{
                                      fontFamily: "Arial, sans-serif",
                                      fontSize: "16px",
                                      color: "#555",
                                    }}
                                  >
                                    <strong>From</strong>: {startTrip.from_loc}{" "}
                                    <br /> <strong>To</strong>:{" "}
                                    {endTrip
                                      ? endTrip.to_loc
                                      : startTrip.to_loc}
                                  </p>
                                )}
                                <p
                                  className="card-text"
                                  style={{
                                    fontFamily: "Arial, sans-serif",
                                    fontSize: "16px",
                                    color: "#555",
                                  }}
                                >
                                  The status for your Booking ID{" "}
                                  <strong>{booking.phone_number}</strong> is:{" "}
                                  <strong>
                                    {booking.status.toUpperCase()}
                                  </strong>
                                </p>
                                {booking.additional_info && (
                                  <div className="mt-3">
                                    <h6
                                      className="card-title"
                                      style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Note
                                    </h6>
                                    <p
                                      className="card-text"
                                      style={{
                                        fontFamily: "Arial, sans-serif",
                                        fontSize: "16px",
                                        color: "#555",
                                      }}
                                    >
                                      {booking.additional_info}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterNew />
    </div>
  );
}

export default VehicleBookingStatus;

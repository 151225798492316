import React, { useState, useEffect } from "react";
import NavNew from "../NavBar/NavNew";
import Faq from "./../FAQ/Faq";
import Contact from "./../Contact/Contact";
import axios from "axios";
import base_url from "../../api/bootapi";
import FooterNew from "../NavBar/FooterNew";
import { Col, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "./../Carousel/Carousel";
import Parser from "html-react-parser";
import NewBannerImage from "../../assets/header-banner.jpg";
import Flight from "../../assets/split-1.jpg";
import Lodgings from "../../assets/split-2.jpg";
import Transit from "../../assets/split-3.jpg";
import Meals from "../../assets/split-4.jpg";
import TourGuides from "../../assets/split-5.jpg";
import logo1 from "../../assets/logo-1.png";
import logo2 from "../../assets/logo-2.png";
import logo3 from "../../assets/logo-3.png";
import logo4 from "../../assets/logo-4.png";
import arrowLeft from "../../assets/arrow-left.png";
import arrowRight from "../../assets/arrow-right.png";
import profile from "../../assets/testimonial-profile.jpg";
import comma from "../../assets/comma.png";
import tourBanner from "../../assets/tour-banner.jpg";
import Nav from "../NavBar/Nav";
toast.configure();

export default function HomeNew() {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const [selectShow, setSelectedShow] = useState(3);

  const [bannerTitle, setBannerTittle] = useState("");
  const [bannerCaption, setBannerCaption] = useState("");
  const [bannerImage, setBannerImg] = useState("");

  const [slideData, setSlideData] = useState();
  const [testimonialData, setTestimonialData] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    getData();
    getSliderData();
    getTestimonialData();
  }, []);
  useEffect(() => {
    if (window.innerWidth <= 1200 && window.innerWidth > 770) {
      setSelectedShow(2);
    } else if (window.innerWidth <= 770) {
      setSelectedShow(1);
    } else {
      setSelectedShow(3);
    }
  }, []);
  useEffect(() => {
    window.onresize = function () {
      // console.log(window.innerHeight);
      console.log(window.innerWidth);
      if (window.innerWidth <= 1200 && window.innerWidth > 770) {
        setSelectedShow(2);
      } else if (window.innerWidth <= 770) {
        setSelectedShow(1);
      } else {
        setSelectedShow(3);
      }
    };
    // if(width <= 1000){
    //   setSelectedShow(2)
    // }
  }, [window.innerWidth]);

  const getData = () => {
    axios
      .get(
        // `${base_url.api1}/cmsapp/cms?id=21` //Live
        // // `${base_url.api1}/cmsapp/cms?id=42` //Test
        `${base_url.api1}/cmsapp/cms_list` //Test
      )
      .then(
        (response) => {
          console.log(response.data.Cms);
          setBannerTittle(response.data.Cms.title);
          setBannerCaption(response.data.Cms.caption);
          setBannerImg(response.data.Cms.image);
        },
        (error) => {
          console.log(JSON.stringify(error));
        }
      );
  };

  const getSliderData = () => {
    axios
      .get(
        `${base_url.api1}/cmsapp/cms?page_name=home&type=slide` //Test
      )
      .then(
        (response) => {
          console.log("100", response.data.Cms.cms_slides);
          setSlideData(response.data.Cms.cms_slides);
        },
        (error) => {
          console.log(JSON.stringify(error));
        }
      );
  };

  const getTestimonialData = () => {
    axios
      .get(
        `${base_url.api1}/cmsapp/cms?page_name=home&type=testimonial` //Test
      )
      .then(
        (response) => {
          console.log("116", response.data.Cms);
          setTestimonialData(response.data.Cms.cms_slides);
        },
        (error) => {
          console.log(JSON.stringify(error));
        }
      );
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  if (!slideData || slideData.length === 0) {
    return null; // If no data, you can return null or a placeholder
  }

  const currentSlideData = slideData[currentSlide];
  const titleWithLineBreaks = currentSlideData.title.replace(/\r\n/g, "<br>");
  const captionWithLineBreaks = currentSlideData.caption.replace(
    /\r\n/g,
    "<br>"
  );
  const imageUrl = `https://apilinsoatours.odisoft.in${currentSlideData.image}`;

  // ====================================== *****************************  ========================================

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialData.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialData.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Check if testimonialsData is defined and not empty
  if (!testimonialData || testimonialData.length === 0) {
    return <p></p>;
  }

  const currentTestimonial = testimonialData[currentIndex];
  const testimonial = `https://apilinsoatours.odisoft.in${currentTestimonial.image}`;

  return (
    <React.Fragment>
      <Nav />
      <div style={{ position: "relative" }}>
        <img
          src={imageUrl}
          alt="Header_Image"
          style={{ height: "100%", width: "100%" }}
        />
        <div className="new_overlaybg">
          <div
            className="main_header_detail"
            style={{ fontFamily: "math !important" }}
          >
            <h1
              style={{ fontFamily: "Domaine Semibold Italic" }}
              dangerouslySetInnerHTML={{ __html: titleWithLineBreaks }}
            />
            <div
              className="bannerCaption hello"
              style={{ fontFamily: "sans-serif" }}
              dangerouslySetInnerHTML={{ __html: captionWithLineBreaks }}
            />
          </div>
          <div className="slider-dots">
            {slideData.map((slide, index) => (
              <span
                key={index}
                className={`dot ${currentSlide === index ? "active" : ""}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>

      {/* <!-- ***** Carousel Start***** --> */}
      <section className="section" style={{ background: "#fff" }}>
        <div className="container">
          <div
            className="text-center"
            style={{ paddingTop: "4%", paddingBottom: "2%" }}
          >
            <h1 className="new_tour_head_2">Enjoy the Spirit</h1>
          </div>
          <Carousel show={selectShow} infiniteLoop={false}>
            <div className="item">
              <div className="new_thumb-wrapper">
                <div className="img-box" style={{ position: "relative" }}>
                  <img
                    src={Flight}
                    className="img-responsive"
                    style={{ height: "420px" }}
                    alt=""
                  />
                  <h4 className="new_cardTitle">Flight</h4>
                </div>
                <div
                  className="thumb-content"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <p>
                    Fly to mekomos hakedoshim with no worries. Linsoa Tours
                    finds and coordinates the best air
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="new_thumb-wrapper">
                <div className="img-box" style={{ position: "relative" }}>
                  <img
                    src={Lodgings}
                    className="img-responsive"
                    style={{ height: "420px" }}
                    alt=""
                  />
                  <h4 className="new_cardTitle">Lodgings</h4>
                </div>
                <div
                  className="thumb-content"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <p>
                    Linsoa Tours provides beautiful, spacious hotels where you
                    will refresh and recharge. All along
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="new_thumb-wrapper">
                <div className="img-box" style={{ position: "relative" }}>
                  <img
                    src={Transit}
                    className="img-responsive"
                    style={{ height: "420px" }}
                    alt=""
                  />
                  <h4 className="new_cardTitle">Transit</h4>
                </div>
                <div
                  className="thumb-content"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="new_thumb-wrapper">
                <div className="img-box" style={{ position: "relative" }}>
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "420px" }}
                    alt=""
                  />
                  <h4 className="new_cardTitle">USA</h4>
                </div>
                <div
                  className="thumb-content"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="new_thumb-wrapper">
                <div className="img-box" style={{ position: "relative" }}>
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "420px" }}
                    alt=""
                  />
                  <h4 className="new_cardTitle">France</h4>
                </div>
                <div
                  className="thumb-content"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <p>
                    Vivamus fermentum in arcu in aliquam. Quisque aliqua porta
                    odio in fringilla vivamus.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="new_thumb-wrapper">
                <div className="img-box" style={{ position: "relative" }}>
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "420px" }}
                    alt=""
                  />
                  <h4 className="new_cardTitle">Poland</h4>
                </div>
                <div
                  className="thumb-content"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="new_thumb-wrapper">
                <div className="img-box" style={{ position: "relative" }}>
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "420px" }}
                    alt=""
                  />
                  <h4 className="new_cardTitle">Canada</h4>
                </div>
                <div
                  className="thumb-content"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="new_thumb-wrapper">
                <div className="img-box" style={{ position: "relative" }}>
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "420px" }}
                    alt=""
                  />
                  <h4 className="new_cardTitle">UK</h4>
                </div>
                <div
                  className="thumb-content"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <p>
                    Vivamus fermentum in arcu in aliquam. Quisque aliqua porta
                    odio in fringilla vivamus.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="new_thumb-wrapper">
                <div className="img-box" style={{ position: "relative" }}>
                  <img
                    src="assets/image/sliced/home/transit.jpeg"
                    className="img-responsive"
                    style={{ height: "420px" }}
                    alt=""
                  />
                  <h4 className="new_cardTitle">Ireland</h4>
                </div>
                <div
                  className="thumb-content"
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                    eu sem tempor, varius quam.
                  </p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </section>
      {/* <!-- ***** Carousel End***** --> */}

      {/* <!-- ***** Enjoy  Spirit  ***** --> */}
      <section
        className="section"
        id="testimonial"
        style={{ textAlign: "center" }}
      >
        <div className="container" style={{ maxWidth: "100%" }}>
          <div className="text-center" style={{ marginBottom: "80px" }}>
            <h1 className="new_tour_head_2" style={{ color: "#005b67" }}>
              Testimonial
            </h1>
            <h5
              style={{
                color: "#545267",
                fontWeight: "500",
                fontStyle: "italic",
                marginBottom: "110px",
              }}
            >
              What our clients has to say
            </h5>
          </div>
          <div className="row mx-0">
            <div className="col-md-1" style={{ padding: "0" }}></div>
            <div
              className="col-md-1"
              style={{
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Previous button */}
              <div onClick={handlePrevClick} style={{ border: "0" }}>
                <img src={arrowLeft} />
              </div>
            </div>
            <div
              className="col-md-8"
              style={{
                backgroundColor: "#fff",
                position: "relative",
                borderRadius: "20px",
                paddingLeft: "0",
                paddingRight: "0",
              }}
            >
              {/* Your existing testimonial content here */}

              {/* Testimonial image */}
              <div className="icon margin_top">
                <img
                  src={comma}
                  alt=""
                  style={{
                    marginTop: "-110px",
                    borderRadius: "90px",
                    left: 0,
                    top: 0,
                    position: "absolute",
                    border: "10px solid #f4f4f4",
                  }}
                />
                <img
                  src={testimonial}
                  alt=""
                  style={{
                    marginTop: "-100px",
                    borderRadius: "100px",
                    border: "15px solid #f4f4f4",
                    marginBottom: "20px",
                  }}
                />
              </div>

              <h5
                className="service-title_1"
                style={{
                  fontSize: "30px",
                  color: "#005b67",
                  fontFamily: "Domaine Semibold Italic",
                  fontWeight: "bolder",
                }}
              >
                John Woods
              </h5>
              <h5
                className="service-title_1"
                style={{
                  fontSize: "15px",
                  color: "#545267",
                  marginBottom: "20px",
                  fontStyle: "italic",
                }}
              >
                {currentTestimonial.title}
              </h5>
              <p
                style={{
                  fontSize: "16px",
                  color: "#000000",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                }}
              >
                {currentTestimonial.body}
              </p>
            </div>
            <div
              className="col-md-1"
              style={{
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Next button */}
              <div onClick={handleNextClick}>
                <img src={arrowRight} />
              </div>
            </div>
            <div className="col-md-1" style={{ padding: "0" }}></div>
          </div>
        </div>
      </section>
      {/* <!-- ***** Enjoy  Spirit ***** --> */}

      {/* <!-- ***** Carousel Start***** --> */}
      <section className="section" style={{ background: "#fff" }}>
        <div className="container">
          <div className="row mx-0">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="item">
                    <div className="new_thumb-wrapper">
                      <div className="img-box" style={{ position: "relative" }}>
                        <img
                          src={Meals}
                          className="img-responsive"
                          style={{ height: "420px" }}
                          alt=""
                        />
                        <h4 className="new_cardTitle">Meals</h4>
                      </div>
                      <div
                        className="thumb-content"
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        <p>
                          Fly to mekomos hakedoshim with no worries. Linsoa
                          Tours finds and coordinates the best air
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item">
                    <div className="new_thumb-wrapper">
                      <div className="img-box" style={{ position: "relative" }}>
                        <img
                          src={TourGuides}
                          className="img-responsive"
                          style={{ height: "420px" }}
                          alt=""
                        />
                        <h4 className="new_cardTitle">Tour Guides</h4>
                      </div>
                      <div
                        className="thumb-content"
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        <p>
                          Linsoa Tours provides beautiful, spacious hotels where
                          you will refresh and recharge. All along
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </section>

      {/* <!-- ***** Carousel End***** --> */}

      {/* <!-- ***** Enjoy  Spirit  ***** --> */}
      <section
        className="section"
        id="about_4"
        style={{
          textAlign: "center",
          backgroundImage: `url(${tourBanner})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container" style={{ maxWidth: "100%" }}>
          <div className="text-center">
            <h1
              className="new_tour_head_2"
              style={{ color: "white", fontSize: "80px" }}
            >
              Private & Vip Tours
            </h1>
          </div>
          <div className="row mx-0">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <p style={{ color: "white", fontSize: "20px" }}>
                Aenean vulputate massa sed neque consectetur, ac fringilla quam
                aliquet. Sed a enim nec eros tempor cursus at id libero.
              </p>
              <a
                href="/"
                className="main-button-slider_4"
                style={{ padding: "16px 32px", fontSize: "21px" }}
              >
                Read More
              </a>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </section>
      {/* <!-- ***** Enjoy  Spirit ***** --> */}

      {/* <section className="section home-client-logo">
        <div className="text-center container">
          <div className="row mx-0">
            <div className="col-md-2">
              <img
                src="assets/image/sliced/home/home-client-logo1.png"
                className="img-fluid"
              />
            </div>
            <div className="col-md-2">
              <img
                src="assets/image/sliced/home/home-client-logo2.png"
                className="img-fluid"
              />
            </div>
            <div className="col-md-2">
              <img
                src="assets/image/sliced/home/home-client-logo3.png"
                className="img-fluid"
              />
            </div>
            <div className="col-md-2">
              <img
                src="assets/image/sliced/home/home-client-logo4.png"
                className="img-fluid"
              />
            </div>
            <div className="col-md-2">
              <img
                src="assets/image/sliced/home/home-client-logo5.png"
                className="img-fluid"
              />
            </div>
            <div className="col-md-2">
              <img
                src="assets/image/sliced/home/home-client-logo6.png"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* <!-- *****Logo Start ***** --> */}
      {/* <div style={{ paddingTop: "3%" }}></div>
            <section className="section">
                <div className="text-center logo_imgs">
                    <div className="row mx-0">
                        <div className="col-md">
                            <img
                                src={logo1}
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md">
                            <img
                                src={logo2}
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md">
                            <img
                                src={logo3}
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md">
                            <img
                                src={logo4}
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md">
                            <img
                                src={logo2}
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md">
                            <img
                                src={logo1}
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <div style={{ paddingTop: "3%" }}></div> */}
      {/* <!-- *****Logo End ***** --> */}

      <FooterNew />
    </React.Fragment>
  );
}

import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Modal from "react-modal";
import { Button, Container, CssBaseline } from "@mui/material";
import AgreementContent from "./AgreementContent"; // Import AgreementContent
import { useSelector } from "react-redux";

const AgreementModal = ({
  isOpen,
  onRequestClose,
  onSignatureAdded,
  onPdfGenerated,
}) => {
  const componentRef = useRef();
  const formData = useSelector((state) => state.claimForm);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Agreement Modal"
      ariaHideApp={false}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxHeight: "90%",
          overflow: "auto",
        },

        overlay: {
          zIndex: 1999, // Ensuring overlay is below the modal content
        },
      }}
    >
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div>
              <AgreementContent
                ref={componentRef}
                onSignatureAdded={onSignatureAdded}
                onPdfGenerated={onPdfGenerated}
                {...formData}
              />
            </div>

            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePrint}
                style={{ marginTop: "35px", marginLeft: "20px" }}
              >
                Print
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={onRequestClose}
                style={{ marginTop: "35px", marginLeft: "20px" }}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default AgreementModal;

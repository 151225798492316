import React, { useState, useEffect } from "react";
// import Nav from "../NavBar/Nav";
import NavNewDark from "../NavBar/NavNewDark";
import NavNew from "../NavBar/NavNew";
import FooterNew from "../NavBar/FooterNew";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
import base_url from "../../api/bootapi";
import attachmenturl from "../../api/attachmenturl";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, CardBody, Card } from "reactstrap";
import { getTour, selectTour } from "../Reducers/tourSlice";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Swal from "sweetalert2";
import { width } from "@mui/system";
import bannerbackground from "../../assets/bannerbackground.jpg";
import Nav from "../NavBar/Nav";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function TourTripPdf() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const navigate = useNavigate();
  const formData = new FormData();
  const [viewModal, setViewModal] = useState(false);
  const handleViewModal = () => setViewModal(!viewModal);

  const [backdropopen, setbackdropopen] = useState(false);
  const [tripData, setTripData] = useState();

  const [country1, setcountry] = useState("United States");
  const [countryValid, setCountryValid] = useState(false);

  const [region, setregion] = useState("New York");
  const [regionValid, setRegionValid] = useState(false);

  const [airParticipants, setAirParticipants] = useState(0);
  const [airParticipantStatus, setAirParticipantStatus] = useState(false);
  const [modeStatus, setModeStatus] = useState(false);
  const [nonAirParticipants, setNonAirParticipants] = useState(0);
  const [totalParticipantStatus, setTotalParticipantStatus] = useState(false);

  const [airFarePrice, setAirFarePrice] = useState();
  const [withoutAirFarePrice, setWithoutAirFarePrice] = useState();

  const [tripSeatData, setTripSeatData] = useState([]);
  const [tripSeatData2, setTripSeatData2] = useState([]);

  const [finalParticipants, setFinalParticipants] = useState();
  const [modeOfTour, setModeOfTour] = useState();

  const [paymentMethod, setPaymentMethod] = useState("Card");

  const [imageData, setImageData] = useState();
  const [tourpdfurl, settourpdfurl] = useState("");
  const [tourstatus, settourstatus] = useState("");

  const [tourTitle, setTourTitle] = useState([]);

  const params = useParams();

  const location = useLocation();

  const tourDetails = location.state?.tourDetails || null;
  const fromWhereCheck = location.state?.fromWhere || null;
  const tourName = location.state?.tour_names || null;

  console.log("84", tourName);
  console.log("85", fromWhereCheck);

  useEffect(() => {
    getAllTourList();
  }, [params.tour_pdf_id]);

  const getAllTourList = () => {
    axios.get(`${base_url.api1}/tourapp/trip_list`, {}).then(
      (response) => {
        console.log("100", response.data.tour);
        // setupcomingTourName(response.data.tour);
        document.getElementById("myForm").reset();
        // Find the object with matching id
        const matchingObject = response?.data?.tour?.find(
          (obj) => obj.id == params?.tour_pdf_id
        );

        // Check if a matching object was found
        if (matchingObject) {
          console.log("Matching tour_names:", matchingObject);
          settourpdfurl(matchingObject.tour_details);
          settourstatus(matchingObject.tour_status);
        } else {
          console.log(
            "No matching object found for the given tour_Enrollment_id"
          );
        }
      },
      (error) => {
        settourpdfurl(null);
        console.log(JSON.stringify(error.response.data));
      }
    );
  };

  const pdfurl = location.state?.tourDetails;
  const fullURL = tourpdfurl ? `${attachmenturl}${tourpdfurl}` : null;
  const Testurl = "https://purple-bertina-48.tiiny.site";

  // const printIframe = (id) => {
  //     console.log(id);
  //     const iframe = document.frames
  //         ? document.frames[id]
  //         : document.getElementById(id);
  //     const iframeWindow = iframe.contentWindow || iframe;

  //     iframe.focus();
  //     iframeWindow.print();
  //     return false;
  // };

  const printIframe = (url) => {
    const newWindow = window.open("", "_blank");

    // Check if the window was successfully opened
    if (newWindow) {
      // Create an iframe in the new window
      const iframe = newWindow.document.createElement("iframe");
      iframe.src = url;
      iframe.style.width = "100%";
      iframe.style.height = "100%";

      // Append the iframe to the new window's document body
      newWindow.document.body.appendChild(iframe);

      // Wait for a short delay before calling print
      setTimeout(() => {
        newWindow.focus();
        newWindow.print();
      }, 1000); // Adjust the delay as needed
    } else {
      console.error("Unable to open new window.");
    }
  };

  // const printIframe = (id, divcontents) => {
  //     var content = document.getElementById(divcontents); // Use the passed divcontents variable
  //     var pri = document.getElementById(id).contentWindow; // Use the passed id variable
  //     pri.document.open();
  //     pri.document.write(content.innerHTML);
  //     pri.document.close();
  //     pri.focus();
  //     pri.print();
  // };

  const handleEnrollmentClick = () => {
    // Navigate to the specified pathname on button click
    navigate({
      pathname: `/view-tour-Enrollment/${params.tour_pdf_id}/`,
      state: { tour_names: tourName },
    });
  };

  return (
    <div style={{ backgroundColor: "#F5F6FA" }}>
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Nav />
        {/* <div style={{ position: "relative", height: "25vh" }}>
          <img
            src={bannerbackground}
            alt="Header_Image"
            style={{ height: "100%", width: "100%" }}
          />
          <div className="overlaybg">
            <div style={{ postion: "absolute" }}>
              <h1
                style={{
                  width: "100%",
                  marginTop: "200px",
                  fontSize: "3.5rem",
                  textAlign: "center",
                  color: "#fff",
                  fontFamily: " Caveat,cursive",
                }}
              >
                {tourTitle ? tourTitle : null}
              </h1>
            </div>
          </div>
        </div> */}

        <div className="container">
          <Row className="px-0">
            <Col lg={12} className="carosol_img">
              <Carousel>
                {tourpdfurl == null ? (
                  <img src="No_image_available.png" width="200" height="200" />
                ) : (
                  <div>
                    <iframe
                      id="tourpdf"
                      src={fullURL}
                      width="100%"
                      height="800px"
                    />
                  </div>
                )}
              </Carousel>
            </Col>
            {tourpdfurl != null ? (
              <>
                <Col lg={8} className="mt-3">
                  {/* Your existing code for rendering content */}
                </Col>
                <Col lg={2} className="mt-3">
                  <Button
                    variant="contained"
                    onClick={() => printIframe(fullURL)}
                    style={{
                      fontWeight: "bold",
                      borderRadius: "40px",
                      padding: "10px 35px",
                      backgroundColor: "#00627e",
                    }}
                  >
                    Print PDF
                  </Button>
                </Col>
                {tourstatus == "Active" ? (
                  <Col lg={2} className="mt-3">
                    <Button
                      variant="primary"
                      onClick={handleEnrollmentClick}
                      style={{
                        fontWeight: "bold",
                        borderRadius: "40px",
                        padding: "10px 35px",
                        backgroundColor: "#00627e",
                      }}
                    >
                      Enrollment
                    </Button>
                  </Col>
                ) : null}
              </>
            ) : null}
          </Row>

          <div className="tourdetails_grid"></div>
        </div>

        <FooterNew />
      </React.Fragment>
    </div>
  );
}
